<template>
  <div>
    <ejs-dialog
      :header="popupSettings.headerMessage"
      ref="deleteConfirmPopup"
      width="400"
      v-bind="dialogProps"
      :allowDragging="true"
      :isModal="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      @close="handleDialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="section-body">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">삭제사유</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea
                                      class="lookup-condition-textarea"
                                      ref="delReason"
                                      v-model="delReason"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li
              v-if="popupSettings.isDeleteButton"
              class="delete keyColor"
            >
              <erp-button
                button-div="DELETE"
                :is-shortcut-button="true"
                @click.native="deleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li
              v-if="popupSettings.isBatchDeleteButton"
              class="delete"
              :class="!popupSettings.isDeleteButton ? 'keyColor' : ''"
            >
              <erp-button
                button-div="DELETE"
                @click.native="batchDeleteButtonClicked"
              >
                일괄삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="closeButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
body .window .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import { validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DeleteConfirmPopup",
  components: {
    InputTextarea,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    propsDeleteButtonClicked: {
      type: Function,
      default: null,
    },
    propsBatchDeleteButtonClicked: {
      type: Function,
      default: null,
    },
  },
  created() {
  },
  data() {
    return {
      isSaved: false,
      delReason: null,
      validateRefList: {
        delReason: {
          required: true
        }
      },
      popupSettings: {
        headerMessage: null,
        isDeleteButton: false,
        isBatchDeleteButton: false,
        buttonAuthNameByReg: null,
      },
      popupData: {},
    };
  },
  computed: {
    dialogProps() {
      return {
        position: { x: "center", y: "center" }
      };
    }
  },
  methods: {
    validateFormRefs,
    handleDialogClose() {
      // isSaved: 저장 여부
      this.$emit("popupClosed", { isSaved: this.isSaved });
    },
    closeButtonClicked() {
      this.$refs.deleteConfirmPopup.hide();
    },
    /**
     * showPopup()
     * @param popupData : $emit Return 값
     * @param popupSettings : 팝업 셋팅 값
     */
    showPopup(popupData, popupSettings) {
      this.popupData = popupData;
      this.popupSettings = {
        headerMessage: popupSettings.headerMessage || null,
        isDeleteButton: popupSettings.isDeleteButton || false,
        isBatchDeleteButton: popupSettings.isBatchDeleteButton || false,
        buttonAuthNameByReg: popupSettings.buttonAuthNameByReg || null,
      };
    },
    async deleteButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      await this.propsDeleteButtonClicked(this.popupData, this.delReason);

      this.isSaved = true;

      this.closeButtonClicked();
    },
    async batchDeleteButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      await this.propsBatchDeleteButtonClicked(this.popupData, this.delReason);

      this.isSaved = true;

      this.closeButtonClicked();
    }
  }
};
</script>