<template>
  <div>
    <ejs-dialog
      ref="availableRoomPopup"
      header="가용 객실"
      width="1620"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">영업일자</div>
                    <ul class="content">
                      <li class="item date">
                        <input-date
                          v-model="searchOptions.bsnDate"
                          type="lookupDetail-condition"
                          :notClear="true"
                          :isGetDateInfo="true"
                          :isUseRoom="true"
                          @change="onDateChange"
                        />
                      </li>
                      <li class="item text">
                        (<span
                          :style="{
                            color: getDayOfWeekCaptionColorByRoom(
                              null,
                              searchOptions.bsnCode,
                              searchOptions.dwCode
                            ),
                          }"
                        >{{ getDayOfWeekCaption(searchOptions.bsnDate) }}</span>)
                        (<span
                          :style="{
                            color: commonCodesGetColorValue(
                              'ROOM_BSN_CODE',
                              searchOptions.bsnCode
                            ),
                          }"
                        >{{ commonCodesGetComName("ROOM_BSN_CODE", searchOptions.bsnCode) }}</span>)
                      </li>
                    </ul>
                    <ul class="content">
                      <li class="item button">
                        <ul class="button">
                          <li class="prev">
                            <erp-button
                                button-div="GET"
                                v-on:click.native="onBsnDatePrevButtonClicked"
                            />
                          </li>
                          <li class="next">
                            <erp-button
                                button-div="GET"
                                v-on:click.native="onBsnDateNextButtonClicked"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">가용 객실</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid" >
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :columns="gridColumns"
                        :dataSource="availableRooms"
                        @headerCellInfo="onGridHeaderCellInfo"
                        @queryCellInfo="onGridQueryCellInfo"
                        @recordClick="onGridRecordClick"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {getDayOfWeekCaptionColorByRoom, getDayOfWeekCaption, getFormattedDate,} from "@/utils/date";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import moment from "moment";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'AvailableRoomPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDate,
    ErpButton,
  },
  data() {
    return {
      searchOptions: {
        bsnDate: null,
        bsnCode:null,
        dwCode:null
      },
      availableRooms: [],
      gridColumns: [],

    };
  },
  created() {},
  mounted() {},
  computed: {
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        isNOColumnDisplay: false,
      };
    },
  },
  methods: {
    getDayOfWeekCaptionColorByRoom,
    getDayOfWeekCaption,
    commonCodesGetColorValue,
    commonCodesGetComName,
    showPopup(args) {
      this.searchOptions.bsnDate = args.bsnDate;
    },
    async onViewButtonClicked() {
      const bsnDateTo = moment(this.searchOptions.bsnDate).add("day", 29).format("YYYY-MM-DD");

      const roomCalendars = await GolfErpAPI.getRoomCalendarByFromDateAndToDate({
        fromDate: this.searchOptions.bsnDate,
        toDate: bsnDateTo,
      });

      const data = await GolfErpAPI.getAvailableRooms({
        bsnDateFrom: this.searchOptions.bsnDate,
        bsnDateTo: bsnDateTo,
      });

      const roomTypes = commonCodesGetCommonCode("ROOM_TYPE", true);

      const dataList = [];
      const divs = [
        {
          code: "R",
          name: "잔여",
        },
        {
          code: "U",
          name: "사용",
        },
        // {
        //   code: "A",
        //   name: "전체",
        // }
      ];

      const gridColumns = [
        {
          field: "roomType",
          headerText: "객실타입",
          type: "string",
          minWidth: 16,
          width: 70,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: 'ROOM_TYPE',
        },
        {
          field: "divName",
          headerText: "구분",
          type: "string",
          minWidth: 16,
          width: 50,
          textAlign: "center",
        },
      ];

      roomCalendars.forEach((item, idx) => {
        gridColumns.push({
          headerText: `${moment(item.bsnDate).format("MM/DD")}`,
          isBorderLeft: idx !== 0 && item.bsnDate.substring(8) === "01",
            columns: [
              {
                field: `dateBy${moment(item.bsnDate).format("YYYYMMDD")}`,
                headerText: `${item.dwName}`,
                type: "number",
                format: "N",
                minWidth: 16,
                width: 48,
                textAlign: "right",
                isBorderLeft: idx !== 0 && item.bsnDate.substring(8) === "01",
                dwCode: item.dwCode,
                roomBsnCode: item.roomBsnCode,
              },
            ],
        });
      });

      roomTypes.forEach(roomType => {
        divs.forEach(div => {
          const pushData = {
            roomType: roomType.comCode,
            divCode: div.code,
            divName: div.name,
          };
          roomCalendars.forEach(roomCalendar => {
            const filterData = data.filter(item => item.roomType === roomType.comCode && item.bsnDate === roomCalendar.bsnDate)[0] || {};

            if (filterData) {
              if (div.code === "A") {
                pushData[`dateBy${moment(roomCalendar.bsnDate).format("YYYYMMDD")}`] = filterData.usefulRoomCnt;
              } else if (div.code === "R") {
                pushData[`dateBy${moment(roomCalendar.bsnDate).format("YYYYMMDD")}`]
                  = filterData.usefulRoomCnt - filterData.blockRoomCnt - filterData.houseRoomCnt - filterData.outofRoomCnt - filterData.resveRoomCnt - filterData.stayRoomCnt;
              } else {
                pushData[`dateBy${moment(roomCalendar.bsnDate).format("YYYYMMDD")}`]
                  = filterData.resveRoomCnt + filterData.stayRoomCnt;
              }
            }
          });

          dataList.push(pushData);
        });
      });

      this.gridColumns = gridColumns;
      this.availableRooms = dataList;
    },
    async onDateChange(args){
      this.searchOptions.bsnDate = args.data;
      this.searchOptions.bsnCode = args.bsnCode;
      this.searchOptions.dwCode = args.dwCode;
      this.onViewButtonClicked();
    },
    onGridHeaderCellInfo(args) {
      const {
        cell:{
          column,
        },
        node
      } = args;
      if(column.dwCode && column.roomBsnCode){
        node.style.color = getDayOfWeekCaptionColorByRoom(null, column.roomBsnCode, column.dwCode);
      }
      if(column.isBorderLeft){
        node.style.borderLeft = "2px solid black";
      }
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column,
        data,
      } = args;

      if (column.isBorderLeft) {
        cell.style.borderLeft = "2px solid black";
      }

      if (column.field === "divName") {
        if (data["divCode"] === "R") {
          cell.style.fontWeight = "bold";
        }
      }

      if (column.field.startsWith("dateBy")) {
        if (data["divCode"] === "R") {
          cell.style.fontWeight = "bold";
          if (data[column.field] <= 0) {
            cell.style.backgroundColor = "#BFBFBF";
          } else {
            cell.classList.add(this.$t("className.grid.clickAreaNone"));
          }
        }
      }

      if (data["divCode"] === "U") {
        cell.style.borderBottom = "2px solid black";
      }

      if (column.field === "roomType") {
        args.rowSpan = 2;
        cell.style.borderBottom = "2px solid black";
      }

      if (data[column.field] === 0) {
        cell.innerText = '-';
      }
    },
    async onGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      if (!["roomType","divName"].includes(field)) {
        if (rowData["divCode"] === "R" && rowData[field] > 0) {
          const bsnDate = getFormattedDate(field.substring(6), "M/D");
          if (!(await this.confirm(`${bsnDate} ${commonCodesGetComName("ROOM_TYPE", rowData["roomType"])} 객실을 예약 하시겠습니까?`))) {
            return;
          }

          this.$emit("popupConfirm", {
            roomType: rowData["roomType"],
            bsnDate: getFormattedDate(field.substring(6)),
          });
          this.onCloseButtonClicked();
        }
      }
    },
    onBsnDatePrevButtonClicked() {
      this.searchOptions.bsnDate = moment(this.searchOptions.bsnDate).add("day",-15).format("YYYY-MM-DD");
    },
    onBsnDateNextButtonClicked() {
      this.searchOptions.bsnDate = moment(this.searchOptions.bsnDate).add("day",15).format("YYYY-MM-DD");
    },
    onCloseButtonClicked() {
      this.$refs.availableRoomPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
  },
};
</script>

<style scoped>
.body-grid >>> .e-gridcontent {
  height: calc( 100% - 50px ) !important;
}
.body-grid>>>.e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell){
  border-left-width: 1px !important;
}
</style>