import Vue from "vue";

/**
 *
 * @param field
 * @param commonCodeDataSource
 * @param EjsGridWrapper object
 * @param eventBus
 * @param isBackground
 * @returns {*}
 */
export default function gridVisitComboboxEditTemplate(
  field,
  commonCodeDataSource,
  grid,
  eventBus,
  isBackground = false
) {
  return {
    template: Vue.component("gridVisitEjsDropdownlistEditTemplate", {
      template: `<div @keydown.capture="onKeydownCapture" @focusout="onFocusOut"><ejs-dropdownlist :name="field" ref="dropdownlist" v-model="data[field]" :dataSource="commonCodeDataSource" :allowFiltering="false" :fields="commonCodeField" :itemTemplate="itemTemplate" @change="onChange"></ejs-dropdownlist></div>`,
      data() {
        return {
          eventState: {
            lockKeyDown: false,
          },
          isEventEmittedInFocusOut: false,
          field: field,
          commonCodeDataSource: commonCodeDataSource,
          data: {
            data: {},
          },
          originalFieldData: null,
          rowIndex: -1,
          grid,
          eventBus,
          isBackground,
          commonCodeField: { text: "comName", value: "comCode" },
        };
      },
      mounted() {
        this.rowIndex = grid.currentSelectedRowIndex;
        this.eventBus.$on("click-open", (field) => {
          this.eventBus.$off("click-open"); // destroyed 가 없음
          if (field === this.field) {
            this.$refs.dropdownlist.ej2Instances.showPopup();
          }
        });
        /*this.$nextTick(() => {
          this.$refs.dropdownlist.ej2Instances.showPopup();
        });*/
      },
      beforeDestroy() {
        this.eventBus.$off("click-open");
      },
      methods: {
        onChange() {
          // focusout 되면 change 이벤트를 발생하지 않음
          // dropdown 펼침 -> 화살표로 이동 -> 다른 셀 클릭으로 이동 시 change 이벤트가 2번 발생하는 문제가 생기므로, 이것을 막기 위함
          if (this.isEventEmittedInFocusOut) {
            return;
          }

          // console.log("## template - onChange " + this.data[field]);
          // console.log(grid.currentSelectedRowIndex);
          // console.log("onChange:" + this.data[field]);

          this.grid.onVisitEjsDropdownListEditTemplateChanged({
            columnName: field,
            rowIndex: this.rowIndex,
            value: this.data[field],
            eventState: this.eventState,
            rowData: this.data,
          });
        },
        onFocusOut() {
          const dropdownListElementArray = Array.prototype.slice.call(
            this.$refs.dropdownlist.getItems()
          );
          const selectedItems = dropdownListElementArray.filter((element) =>
            element.getAttribute("aria-selected")
          );
          if (selectedItems.length === 1) {
            const selectedValue = selectedItems[0].getAttribute("data-value");
            if (this.data[field] !== selectedValue) {
              this.data[field] = selectedValue;
              // console.log("onFocusOut:" + selectedValue);
              this.isEventEmittedInFocusOut = true;
              // console.log("## template - onFocusOut " + this.data[field]);

              this.grid.onVisitEjsDropdownListEditTemplateChanged({
                columnName: field,
                rowIndex: this.rowIndex,
                value: this.data[field],
                eventState: this.eventState,
              });
            }
          }

          this.$refs.dropdownlist.ej2Instances.hidePopup();
        },
        onKeydownCapture(e) {
          // console.log(`#### eventState.lockKeyDown: ${this.eventState.lockKeyDown} ###`);
          if (this.eventState.lockKeyDown) {
            e.stopPropagation();
            e.preventDefault();
          }
        },
        itemTemplate() {
          const isBackground = this.isBackground;
          return {
            template: Vue.component("itemTemplate", {
              template: `<div class="dev-dropdownlist-edit-template" :style="style">{{data.comName}}</div>`,
              computed: {
                style() {
                  return {
                    color: this.data.colorValue && !isBackground
                      ? this.data.colorValue
                      : '#666',
                    backgroundColor: this.data.colorValue && isBackground
                      ? this.data.colorValue
                      : 'transparent'
                  };
                }
              },
              data() {
                return {
                  data: {},
                  isBackground
                };
              },
            }),
          };
        },
      },
    }),
  };
}
