<template>
  <div>
    <ejs-dialog
      ref="RoomExtTrPopup"
      header="Extension/Trans"
      width="710"
      height="552"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">일자</div>
                    <ul class="content">
                      <li class="item date">
                        <input-date
                          v-model="bsnDate"
                          :notClear="true"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">검색조건</div>
                    <ul class="content">
                      <li class="item" style="width: 180px;">
                        <input-text
                          v-model="searchValue"
                          placeholder="예약번호/객실번호/투숙자명"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article article-01">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">객실 목록</div>
                      <div class="header-caption">[{{numberWithCommas(roomListCount)}}건]</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="roomGrid"
                      v-bind="roomGridProps"
                      :dataSource="roomList"
                      @rowSelected="onRoomGridRowSelected"
                    />
                  </div>
                </section>
              </article>
              <article class="body-article article-02">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">작업 선택</div>
                      <ul class="header-label">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <label>
                                <input
                                  type="radio"
                                  value="EXT"
                                  v-model="extTrDiv"
                                />
                                <i></i>
                                <div class="label">투숙일 변경</div>
                              </label>
                            </li>
                          </ul>
                        </li>
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <label>
                                <input
                                  type="radio"
                                  value="TR"
                                  v-model="extTrDiv"
                                />
                                <i></i>
                                <div class="label">객실 변경</div>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <erp-button
                            button-div="SAVE"
                            @click.native="onSaveButtonClicked"
                          >
                            저장
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article article-0201">
                      <div class="article-left">
                        <section class="article-section section-020101">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">투숙일 변경</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="body-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <div class="title">퇴실일자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input stayCnt">
                                            <div class="form">
                                              <input-number
                                                ref="dayCnt"
                                                v-model="extensionData.dayCnt"
                                                :disabled="extTrDiv !== 'EXT'"
                                                :allowMinus="true"
                                                @change="onExtDayCntChanged"
                                              />
                                            </div>
                                          </li>
                                          <li
                                            class="item form-group text"
                                            :class="extTrDiv !== 'EXT' ? 'disabled' : ''"
                                          >
                                            박
                                          </li>
                                          <li
                                            class="item form-group text"
                                            :class="extTrDiv !== 'EXT' ? 'disabled' : ''"
                                          >
                                            {{extensionData.departureDate}}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">객실번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li
                                            class="item form-group text roomNo"
                                            :class="extTrDiv !== 'EXT' ? 'disabled' : ''"
                                          >
                                            {{extensionData.roomNo}}
                                          </li>
                                          <li
                                            class="item form-group text"
                                            :class="extTrDiv !== 'EXT' ? 'disabled' : ''"
                                          >
                                            {{commonCodesGetComName("ROOM_TYPE", extensionData.roomType)}}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="article-right">
                        <section class="article-section section-020102">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">객실 변경</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="body-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <div class="title">퇴실일자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li
                                            class="item form-group text"
                                            :class="extTrDiv !== 'TR' ? 'disabled' : ''"
                                          >
                                            {{transData.departureDate}}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">객실번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li
                                            class="item form-group text transRoomNo"
                                            :class="extTrDiv !== 'TR' ? 'disabled' : ''"
                                          >
                                            {{transData.roomNo}}
                                          </li>
                                          <li
                                            class="item form-group text roomType"
                                            :class="extTrDiv !== 'TR' ? 'disabled' : ''"
                                          >
                                            {{commonCodesGetComName("ROOM_TYPE", transData.roomType)}}
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="onRoomSearchButtonClicked"
                                                >
                                                  검색
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <vacant-room-popup
      v-if="isVacantRoomPopup"
      ref="vacantRoomPopup"
      @popupClosed="onVacantRoomPopupClosed"
    />
  </div>
</template>

<style scoped>
body .window .windowContent .body-article.article-01 .section-body {overflow: visible; border: none;}
body .window .windowContent .body-article.article-01 {height: 260px;}
body .window .windowContent .body-article.article-02 {height: calc(100% - 260px);}
body .window .windowContent .body-article .article-section .section-header+.section-body {height: calc(100% - 46px);}

body .window .windowContent .body-article.article-0201 .section-020101 .section-body {border: none;}
body .window .windowContent .body-article.article-0201 .section-020102 .section-body {border: none;}

body .window .windowContent .article-section.section-020101 .body-data .field .title {width: 90px;}
body .window .windowContent .article-section.section-020101 .body-data .field .content {width: calc((100% - 90px) + 1px);}

body .window .windowContent .body-data .data-content .field {width: 100%;}
body .window .windowContent .body-data .data-content .field .content {border-right: none;}
body .window .windowContent .body-data .data-content .field .item.stayCnt {width: 70px;}
body .window .windowContent .body-data .data-content .field .item.roomNo {width: 95px;}
body .window .windowContent .body-data .data-content .field .item.transRoomNo {width: 80px;}
body .window .windowContent .body-data .data-content .field .item.text.roomType {width: calc(100% - 103px);}
body .window .windowContent .body-data .data-content .field .item.text.disabled {color: #bbb;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputDate from "@/components/common/datetime/InputDate";
import InputText from "@/components/common/text/InputText";
import InputNumber from "@/components/common/InputNumber";
import VacantRoomPopup from "@/views/room-reservation-management/popup/VacantRoomPopup";
import ErpButton from "@/components/button/ErpButton.vue";

import moment from "moment";
import {numberWithCommas} from "@/utils/number";
import {commonCodesGetComName} from "@/utils/commonCodes";

export default {
  name: 'RoomExtTrPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputNumber,
    InputDate,
    EjsGridWrapper,
    VacantRoomPopup,
    ErpButton,
  },
  data() {
    return {
      isSaved: false,
      isVacantRoomPopup: false,
      popupData: null,
      bsnDate: null,
      searchValue: null,
      roomList: [],
      roomListCount: 0,
      extTrDiv: "EXT",
      orgExtTrData: {
        stayId: null,
        arrivalDate: null,
        departureDate: null,
        dayCnt: 0,
        roomId: null,
        roomNo: null,
        roomType: null,
      },
      extensionData: {
        stayId: null,
        arrivalDate: null,
        departureDate: null,
        dayCnt: 0,
        roomId: null,
        roomNo: null,
        roomType: null,
      },
      transData: {
        stayId: null,
        arrivalDate: null,
        departureDate: null,
        dayCnt: 0,
        roomId: null,
        roomNo: null,
        roomType: null,
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    roomGridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        allowSorting: false,
        allowFiltering: false,
        noColumnWidth: 35,
        columns: [
          {
            field: "stayId",
            type: "number",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "Center",
          },
          {
            field: "roomType",
            headerText: "타입",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "Center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "Center",
          },
          {
            field: "stayCnt",
            headerText: "박",
            type: "number",
            minWidth: 16,
            width: 30,
            textAlign: "Center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "Center",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 70,
            textAlign: "right",
          },
          {
            field: "resveName",
            headerText: "예약자명",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "reprsntRoomFlag",
            headerText: "대표",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 50,
            textAlign: "center",
          },
          {
            field: "frpyAbleFlag",
            headerText: "후불",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "center",
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    commonCodesGetComName,
    showPopup(args) {
      console.log('showPopup.args.===>', args);
      this.popupData = args;

      this.bsnDate = args.arrivalDate;
      this.searchValue = args.roomNo;

      this.fetchRoomCheckInDetailByStayId();
    },
    async fetchRoomCheckInDetailByStayId() {
      const data = await GolfErpAPI.fetchRoomCheckInDetailByOrSearch({
        bsnDate: this.bsnDate,
        searchValue: this.searchValue,
      });

      console.log('data.===>', data);
      this.roomList = data.map(item => ({
        ...item,
        resveName: item.trResveMember.resveName,
        roomAmt: (item.trResveStayPaymts || []).map(paymt => paymt.roomAmt).reduce((acc, cur) => acc + cur),
      }));
      this.roomListCount = this.roomList.length;
    },
    onCloseButtonClicked() {
      this.$refs.RoomExtTrPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", { isSaved: this.isSaved });
    },
    onViewButtonClicked() {
      this.fetchRoomCheckInDetailByStayId();
    },
    onRoomGridRowSelected(args) {
      console.log('onRoomGridRowSelected.args.===>', args);
      const {
        data
      } = args;

      this.orgExtTrData.stayId = data.stayId;
      this.orgExtTrData.arrivalDate = data.arrivalDate;
      this.orgExtTrData.departureDate = data.departureDate;
      this.orgExtTrData.dayCnt = 0;
      this.orgExtTrData.roomId = data.roomId;
      this.orgExtTrData.roomNo = data.roomNo;
      this.orgExtTrData.roomType = data.roomType;

      this.extensionData = JSON.parse(JSON.stringify(this.orgExtTrData));

      this.transData = JSON.parse(JSON.stringify(this.orgExtTrData));
    },
    async onSaveButtonClicked() {
      try {
        await GolfErpAPI.checkAvailableRooms({
          fromDate: this.extTrDiv === "EXT" ? this.extensionData.arrivalDate : this.transData.arrivalDate,
          toDate: this.extTrDiv === "EXT" ? this.extensionData.departureDate : this.transData.departureDate,
          roomType: this.extTrDiv === "EXT" ? this.extensionData.roomType : this.transData.roomType,
          stayId: this.extTrDiv === "EXT" ? this.extensionData.stayId : this.transData.stayId,
        });
      } catch (e) {
        console.error(e);
      }

      if (this.extTrDiv === "TR" && this.orgExtTrData.roomId === this.transData.roomId) {
        this.errorToast("변경하려는 객실이 기존 객실과 같습니다. 다시 확인해 주시기 바랍니다");
        return;
      }

      if (!(await this.confirm("저장하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.roomExtensionTrans({
        stayId: this.orgExtTrData.stayId,
        div: this.extTrDiv,
        departureDate: this.extTrDiv === "EXT" ? this.extensionData.departureDate : this.transData.departureDate,
        roomType: this.extTrDiv === "TR" ? this.transData.roomType : this.extensionData.roomType,
        roomId: this.extTrDiv === "TR" ? this.transData.roomId : this.extensionData.roomId,
      });

      this.infoToast("저장되었습니다");

      if (this.searchValue) {
        this.searchValue = this.extTrDiv === "TR" ? this.transData.roomNo : this.extensionData.roomNo;
      }

      this.fetchRoomCheckInDetailByStayId();
    },
    async onExtDayCntChanged(value) {
      this.$refs.dayCnt.blur();

      const departureDate = this.orgExtTrData.departureDate;
      const changeDepartureDate = moment(departureDate).add(value, "d").format("YYYY-MM-DD");

      if (moment(changeDepartureDate).diff(this.extensionData.arrivalDate, "days") < 0) {
        this.errorToast("퇴실일자가 입실일자보다 과거일 수 없습니다");
        return;
      }

      this.extensionData.departureDate = changeDepartureDate;

      await GolfErpAPI.checkAvailableRooms({
        fromDate: this.extTrDiv === "EXT" ? this.extensionData.arrivalDate : this.transData.arrivalDate,
        toDate: this.extTrDiv === "EXT" ? this.extensionData.departureDate : this.transData.departureDate,
        roomType: this.extTrDiv === "EXT" ? this.extensionData.roomType : this.transData.roomType,
        stayId: this.extTrDiv === "EXT" ? this.extensionData.stayId : this.transData.stayId,
      });
    },
    onRoomSearchButtonClicked() {
      this.isVacantRoomPopup = true;
      this.$nextTick(() => {
        this.$refs.vacantRoomPopup.showPopup({
          arrivalDate: this.transData.arrivalDate,
          departureDate: this.transData.departureDate,
          roomType: this.transData.roomType,
          stayId: this.transData.stayId,
          rowIndex: null,
          roomIds: null,
        });
      });
    },
    async onVacantRoomPopupClosed(rowIndex, data) {
      this.isVacantRoomPopup = false;

      if (data) {
        this.transData.roomType = data.roomType;
        this.transData.roomId = data.roomId;
        this.transData.roomNo = data.roomNo;

        await GolfErpAPI.checkAvailableRooms({
          fromDate: this.extTrDiv === "EXT" ? this.extensionData.arrivalDate : this.transData.arrivalDate,
          toDate: this.extTrDiv === "EXT" ? this.extensionData.departureDate : this.transData.departureDate,
          roomType: this.extTrDiv === "EXT" ? this.extensionData.roomType : this.transData.roomType,
          stayId: this.extTrDiv === "EXT" ? this.extensionData.stayId : this.transData.stayId,
        });
      }
    },
  },
};
</script>
