import {commonCodeGetComCodeDefaultValue, commonCodesGetStandardInfo} from "@/utils/commonCodes";

/**
 * 세금 계산
 * @param totAmt {number},
 * @param notaxCode {string},
 * @param isServiceAmount {boolean}
 */
export function calculateTaxAmount(totAmt = 0, notaxCode = "TAX", isServiceAmount = false) {
  totAmt = typeof totAmt !== "number" ? Number(totAmt) : totAmt;

  if (isNaN(totAmt)) {
    this.errorToast("세금 계산에 필요한 값이 숫자가 아닙니다");
    return;
  }

  if (totAmt === 0) {
    return {
      netAmt: 0,
      svcAmt: 0,
      vatAmt: 0,
    };
  }

  const vatCalculationMethod = commonCodesGetStandardInfo("vatCalcMethod") || commonCodeGetComCodeDefaultValue("VAT_CALC_METHOD");

  if (!vatCalculationMethod) {
    this.errorToast("[환경설정] 부가세 계산 방식을 설정해 주시기 바랍니다");
    return;
  }

  const vatRate = commonCodesGetStandardInfo("vat");

  if (vatRate === null || vatRate === undefined) {
    this.errorToast("[환경설정] 부가세 계산 비율을 설정해 주시기 바랍니다");
    return;
  }

  const svcRate = 1.1;

  // 부가세
  let vatAmt = 0;
  if (notaxCode !== "NOTAX") {
    if (vatRate !== 0) {
      if (vatCalculationMethod === "ROUND") {
        vatAmt = totAmt - Math.round(totAmt / vatRate);
      } else {
        vatAmt = totAmt - Math.trunc(totAmt / vatRate);
      }
    }
  }

  // 공급가액
  const netAmt = totAmt - vatAmt;

  // 봉사료
  let svcAmt = 0;
  if (isServiceAmount) {
    if (vatCalculationMethod === "ROUND") {
      svcAmt = netAmt - Math.round(netAmt / svcRate);
    } else {
      svcAmt = netAmt - Math.trunc(netAmt / svcRate);
    }
  }

  return {
    netAmt: netAmt,
    svcAmt: svcAmt,
    vatAmt: vatAmt,
  };
}
