<template>
  <div>
    <ejs-dialog
      ref="CheckInRoomSearchPopup"
      :header="`객실 리스트 [${searchOptions.bsnDate}]`"
      width="470"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article article-01">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">객실 목록</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="roomGrid"
                      v-bind="roomGridProps"
                      :dataSource="checkInRooms"
                      @onGridDialogDoubleClickedOrEnterKeyed="onConfirmButtonClicked"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onConfirmButtonClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

import { orderBy as _orderBy } from "lodash";

export default {
  name: 'CheckInRoomSearchPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      searchOptions: {
        bsnDate: null,
        rresveNo: null,
      },
      checkInRooms: [],
      checkInRoomsCount: 0,
    };
  },
  created() {},
  mounted() {},
  computed: {
    roomGrid() {
      return this.$refs.roomGrid;
    },
    roomGridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        isInPopup: true,
        noColumnWidth: 35,
        columns: [
          {
            field: "stayId",
            type: "string",
            minWidth: 16,
            width: 90,
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "reprsntRoomFlag",
            headerText: "대표",
            type: "boolean",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "isPay",
            headerText: "정산",
            type: "boolean",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
        ],
      };
    },
  },
  methods: {
    showPopup(args) {
      this.searchOptions = args;

      this.fetchCheckInRooms();
    },
    async fetchCheckInRooms() {
      const data = await GolfErpAPI.fetchCheckInRooms(this.searchOptions.rresveNo);

      this.checkInRooms = _orderBy(data.filter(item => ["CHECKIN", "CHECKOUT"].includes(item.rresveStatus) && item.frpyAbleFlag).map(item => {
        return ({
          ...item,
          isPay: item.rresveStatus === "CHECKOUT",
        });
      }), ["arrivalDate", "roomNo"]);
    },
    onCloseButtonClicked() {
      this.$refs.CheckInRoomSearchPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
    onConfirmButtonClicked() {
      const selectedRecords = this.roomGrid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("객실을 선택해 주시기 바랍니다");
        return;
      }

      if (selectedRecords[0].isPay) {
        this.errorToast(`${selectedRecords[0].roomNo}호 객실은 체크아웃 상태이므로 POSTING이 불가합니다`);
        return;
      }

      this.$emit("popupConfirm", {
        roomId: selectedRecords[0].roomId,
        guestName: selectedRecords[0].guestName,
        stayId: selectedRecords[0].stayId,
      });
    },
  },
};
</script>
