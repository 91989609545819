<template>
  <div class="content-wrapper roomCheckIn">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">조회구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="searchOptionsSearchDiv"
                  v-model="searchOptions.searchDiv"
                  :dataSource="searchOptions.searchDivCodes"
                  :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">{{ bsnDateTitle }}</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="searchOptionsBsnDate"
                  v-model="searchOptions.bsnDate"
                  type="lookup-condition"
                  :notClear="true"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">그룹명</div>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchOptionsGroupName"
                  v-model="searchOptions.groupName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약자</div>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchOptionsResveName"
                  v-model="searchOptions.resveName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">투숙객</div>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchOptionsGuestName"
                  v-model="searchOptions.guestName"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="viewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article
        class="body-article"
        :class="
          isRoomResveDetailViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">체크인 목록</div>
                <div class="header-caption">[{{ numberWithCommas(roomReservationsCount) }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onClickExcel"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="roomResveGrid"
                :dataSource="roomReservations"
                v-bind="roomResveGridOptions"
                @headerCellInfo="onRoomResveGridHeaderCellInfo"
                @queryCellInfo="onRoomResveGridQueryCellInfo"
                @recordClick="onRoomResveGridRecordClick"
                @rowSelected="onRoomResveGridRowSelected"
                @actionComplete="onRoomResveGridActionComplete"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">객실 체크인 상세</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button">
                <li class="save keyColor">
                  <erp-button
                    button-div="SAVE"
                    :is-shortcut-button="true"
                    :ignore="isPopupOpened || !isRoomResveDetailViewOpened"
                    @click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li>
                  <erp-button
                    button-div="GET"
                    :disabled="true"
                    @click.native="onChangeHistoryButtonClicked"
                  >
                    변경이력
                  </erp-button>
                </li>
                <li class="close">
                  <erp-button button-div="CLOSE" @click.native="onRoomReservationDetailViewClosed">
                    닫기
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body" v-show="isRoomResveDetailViewOpened">
              <article class="body-article article-01">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약정보</div>
                      <div
                        class="header-caption"
                        v-if="roomReservationDetail.reservationInfo.rresveNo"
                      >
                        [예약번호
                        <strong>{{
                            roomReservationDetail.reservationInfo.rresveNo
                          }}</strong
                        >]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article article-0101">
                      <div class="article-left">
                        <section class="article-section section-020101">
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field groupName">
                                      <!-- 필수 : required -->
                                      <div class="title required">그룹명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input">
                                            <div class="form">
                                              <input-text
                                                ref="groupName"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .groupName
                                                "
                                                :maxlength="10"
                                                @change="onGroupNameChanged"
                                              />
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                    onMemberPopupOpen(
                                                      true,
                                                      memberPopupType.GROUP_NAME,
                                                      roomReservationDetail
                                                        .reservationInfo.groupName,
                                                      null
                                                    )
                                                  "
                                                >
                                                  검색
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field golfPackageLink">
                                      <!-- 필수 : required -->
                                      <div class="title">골프연결</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li
                                            class="item form-group text"
                                            :style="`${roomReservationDetail.reservationInfo.golfPackageLink ? 'cursor: pointer;' : ''}`"
                                            @click="onGolfPackageLinkClicked"
                                          >
                                            {{ roomReservationDetail.reservationInfo.golfPackageLink }}
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="onGolfPackageLinkButtonClicked"
                                                >
                                                  검색
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field grpName">
                                      <!-- 필수 : required -->
                                      <div class="title">
                                        단체명
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input">
                                            <div class="form">
                                              <input-text
                                                ref="grpName"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .grpName
                                                "
                                                @change="onGrpNameChanged"
                                              />
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                    onGroupPopupOpen(true)
                                                  "
                                                >
                                                  검색
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                          <li class="item form-group select">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .grpKind
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.grpKindOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                              />
                                            </div>
                                          </li>
                                          <li class="item form-group text">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .grpNo
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field reservationName">
                                      <!-- 필수 : required -->
                                      <div class="title required">예약자명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input">
                                            <div class="form">
                                              <input-text
                                                ref="resveName"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .resveName
                                                "
                                                :maxlength="10"
                                                @change="onResveNameChanged"
                                              />
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                    onMemberPopupOpen(
                                                      true,
                                                      memberPopupType.RESV,
                                                      roomReservationDetail
                                                        .reservationInfo.resveName,
                                                      null
                                                    )
                                                  "
                                                >
                                                  검색
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">회원번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            {{
                                              memberNoFormatter(roomReservationDetail.reservationInfo
                                                .memberNo)
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">회원구분</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="memberDiv"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .memberDiv
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.memberDivOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                                @change="
                                                  onReservationInfoMemberDivDropdownListChanged
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">회원등급</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="memberGrade"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .memberGrade
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.memberGradeOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">연락자명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="contactName"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .contactName
                                                "
                                                :maxlength="10"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">연락처</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div
                                              v-if="commonMixinHasCiperTextGet"
                                              class="form"
                                            >
                                              <component-telephone
                                                ref="contactTelInfo"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .contactTel
                                                "
                                                :max-length="11"
                                                @blur="onContactTelInfoBlur"
                                              />
                                            </div>
                                            <template v-else>
                                              {{
                                                roomReservationDetail.reservationInfo
                                                  .contactTel
                                              }}
                                            </template>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">예약종류</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="resveKind"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .resveKind
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.resveKindOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                                @change="onResveKindChanged"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">예약채널</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="resveChannel"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .resveChannel
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.resveChannelOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">지역</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .areaCode
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.areaCodeOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">성별</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .sexCode
                                                "
                                                :dataSource="
                                                  roomReservationViewOptions.sexCodeOptions
                                                "
                                                :allowFiltering="false"
                                                :fields="
                                                  commonCodeFields
                                                "
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="article-right">
                        <section class="article-section section-020102">
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field remarks">
                                      <!-- 필수 : required -->
                                      <div class="title">비고</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea
                                                ref="resveRemarks"
                                                style="height: 47px;"
                                                :resize="false"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .resveRemarks
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field frontMemo">
                                      <!-- 필수 : required -->
                                      <div class="title">프론트메모</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea
                                                ref="frontMemo"
                                                style="height: 47px;"
                                                :resize="false"
                                                v-model="
                                                  roomReservationDetail.reservationInfo
                                                    .frontMemo
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field vipFlag">
                                      <!-- 필수 : required -->
                                      <div class="title">VIP 여부</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <ul class="check">
                                              <li>
                                                <label>
                                                  <input
                                                    name="vipFlag"
                                                    type="radio"
                                                    v-model="roomReservationDetail.reservationInfo.vipFlag"
                                                    :value="true"
                                                  />
                                                  <i></i>
                                                  <div class="label">예</div>
                                                </label>
                                              </li>
                                              <li>
                                                <label>
                                                  <input
                                                    name="vipFlag"
                                                    type="radio"
                                                    v-model="roomReservationDetail.reservationInfo.vipFlag"
                                                    :value="false"
                                                  />
                                                  <i></i>
                                                  <div class="label">아니오</div>
                                                </label>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field sendSMS">
                                      <!-- 필수 : required -->
                                      <div class="title">SMS 전송</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group text-01">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .smsSendFlag
                                                ? "전송"
                                                : "미전송"
                                            }}
                                          </li>
                                          <li class="item form-group text-02">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .smsSendCnt
                                            }}건
                                          </li>
                                          <li class="item form-group text">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .smsSendDt
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field enrollment">
                                      <!-- 필수 : required -->
                                      <div class="title">등록/수정</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group text-01">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .insertName
                                            }}
                                          </li>
                                          <li class="item form-group text">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .insertDt
                                            }}
                                          </li>
                                          <li class="item form-group text-01">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .updateName
                                            }}
                                          </li>
                                          <li class="item form-group text">
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .updateDt
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0202">
                  <div class="section-caption">
                    <div class="caption-navigation">
                      <ejs-tab
                        ref="tabByRoomDetail"
                        heightAdjustMode="Auto"
                        :showCloseButton="false"
                        :items="tabItems"
                        @selected="onTabSelected"
                      />
                    </div>
                  </div>
                  <div
                    class="section-body"
                    v-show="selectTablDiv === 'CHECKIN'"
                    style="height: calc(100% - 35px);"
                  >
                    <article class="body-article">
                      <section class="article-section section-020201">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">객실 체크인 상세</div>
                            <div class="header-caption">[{{ numberWithCommas(roomReservationDetail.reservationDetailsCount) }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onRoomPostingPopupOpen"
                                >
                                  Posting
                                </erp-button>
                              </li>
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onExtTrButtonClicked"
                                >
                                  EXT/TR
                                </erp-button>
                              </li>
                              <li class="add">
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onRoomDetailAddButtonClicked"
                                >
                                  객실추가
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          style="height: calc(100% - 43px);"
                          @click.capture="onRoomResveDetailGridClick"
                        >
                          <ejs-grid-wrapper
                            ref="roomResveDetailGrid"
                            :dataSource="roomReservationDetail.reservationDetails"
                            v-bind="roomResveDetailGridOptions"
                            @dataBound="onRoomResveDetailGridDataBound"
                            @headerCellInfo="onRoomResveDetailGridHeaderCellInfo"
                            @queryCellInfo="onRoomResveDetailGridQueryCellInfo"
                            @cellSave="onRoomResveDetailGridCellSave"
                            @cellSaved="onRoomResveDetailGridCellSaved"
                            @recordClick="onRoomResveDetailGridRecordClick"
                            @rowSelected="onRoomResveDetailGridRowSelected"
                            @actionComplete="onRoomResveDetailGridActionComplete"
                            @gridCheckboxChanged="onGridCheckboxChanged"
                            @visitEjsDropdownListEditTemplateChanged="onVisitEjsDropdownListEditTemplateChanged"
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                  <div
                    class="section-body"
                    v-show="selectTablDiv === 'CHECKOUT'"
                    style="height: calc(100% - 35px);"
                  >
                    <article class="body-article">
                      <section class="article-section section-020201 checkin">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">객실 체크아웃 목록</div>
                            <div class="header-caption">[{{ numberWithCommas(roomReservationDetail.checkoutListCount) }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onAllCheckoutButtonClicked"
                                >
                                  전체 체크아웃
                                </erp-button>
                              </li>
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onCheckoutButtonClicked"
                                >
                                  체크아웃
                                </erp-button>
                              </li>
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onTotalSumButtonClicked"
                                >
                                  전체합산
                                </erp-button>
                              </li>
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onRestorationButtonClicked"
                                >
                                  환원
                                </erp-button>
                              </li>
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onPayButtonClicked"
                                >
                                  정산
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          style="height: calc(100% - 43px);"
                        >
                          <ejs-grid-wrapper
                            ref="roomCheckoutGrid"
                            :dataSource="roomReservationDetail.checkoutList"
                            v-bind="roomCheckoutGridOptions"
                            @queryCellInfo="onRoomCheckoutGridQueryCellInfo"
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                  <div
                    class="section-body"
                    v-show="selectTablDiv === 'CASHIER'"
                    style="height: calc(100% - 35px);"
                  >
                    <article class="body-article">
                      <section class="article-section section-020201 checkin">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">CASHIER 목록</div>
                            <div class="header-caption">[{{ numberWithCommas(roomReservationDetail.cashierListCount) }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onRoomPostingPopupOpen"
                                >
                                  Posting
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          style="height: calc(100% - 43px);"
                        >
                          <ejs-grid-wrapper
                            ref="roomCashierGrid"
                            :dataSource="roomReservationDetail.cashierList"
                            v-bind="roomCashierGridOptions"
                            @queryCellInfo="onRoomCashierGridQueryCellInfo"
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                  <div
                    class="section-body"
                    v-show="selectTablDiv === 'SALES'"
                    style="height: calc(100% - 35px);"
                  >
                    <article class="body-article">
                      <section class="article-section section-020201 checkin">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">매출 목록</div>
                            <div class="header-caption">[{{ numberWithCommas(salesListCount) }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-check">
                              <li>
                                <div style="padding-right: 6px;">그룹구분</div>
                              </li>
                              <li>
                                <label>
                                  <input
                                    type="radio"
                                    name="salesGroupDiv"
                                    v-model="searchOptions.salesGroupDiv"
                                    value="DATE"
                                  />
                                  <i></i>
                                  <div class="label">일자별</div>
                                </label>
                              </li>
                              <li style="margin-right: 15px;">
                                <label>
                                  <input
                                    type="radio"
                                    name="salesGroupDiv"
                                    v-model="searchOptions.salesGroupDiv"
                                    value="STORE"
                                  />
                                  <i></i>
                                  <div class="label">매장별</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    v-model="searchOptions.isExcludSalesCancel"
                                  />
                                  <i></i>
                                  <div class="label">취소제외</div>
                                </label>
                              </li>
                            </ul>
                            <ul class="header-button">
                              <li>
                                <erp-button
                                  button-div="SAVE"
                                  @click.native="onRoomPostingPopupOpen"
                                >
                                  Posting
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          style="height: calc(100% - 43px);"
                        >
                          <ejs-grid-wrapper
                            ref="roomSalesGrid"
                            :dataSource="salesList"
                            v-bind="roomSalesGridOptions"
                            @queryCellInfo="onRoomSalesGridQueryCellInfo"
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                  <div
                    class="section-body"
                    v-show="selectTablDiv === 'CREDIT'"
                    style="height: calc(100% - 35px);"
                  >
                    <article class="body-article">
                      <section class="article-section section-020201 checkin">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">정산 목록</div>
                            <div class="header-caption">[{{ numberWithCommas(creditListCount) }}건]</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-check">
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    v-model="searchOptions.isExcludCreditCancel"
                                  />
                                  <i></i>
                                  <div class="label">취소제외</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          style="height: calc(100% - 43px);"
                        >
                          <ejs-grid-wrapper
                            ref="roomCreditGrid"
                            :dataSource="creditList"
                            v-bind="roomCreditGridOptions"
                            @headerCellInfo="onRoomCreditGridHeaderCellInfo"
                            @queryCellInfo="onRoomCreditGridQueryCellInfo"
                          />
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <room-reservation-registration-search-detail-popup
      v-if="isSearchDetailPopupOpen"
      ref="roomReservationRegistrationSearchDetailPopup"
      :searchOptions="searchOptions"
      @popupClosed="onSearchDetailPopupClose"
      @viewButtonClicked="viewButtonClicked"
      @onInitButtonClicked="onInitButtonClicked"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="memberPopupPosition"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <available-room-popup
      v-if="isAvailableRoomPopup"
      ref="availableRoomPopup"
      @popupClosed="onAvailableRoomPopupClosed"
      @popupConfirm="onAvailableRoomPopupConfirm"
    />
    <room-rate-details-by-date-popup
      v-if="isRoomRateDetailsByDatePopup"
      ref="roomRateDetailsByDatePopup"
      @popupClosed="onRoomRateDetailsByDatePopupClosed"
    />
    <room-reservation-cancel-popup
      v-if="isRoomReservationCancelPopup"
      ref="roomReservationCancelPopup"
      @popupClosed="onRoomReservationCancelPopupClosed"
    />
    <golf-package-link-popup
      v-if="isGolfPackageLinkPopup"
      ref="golfPackageLinkPopup"
      @popupClosed="onGolfPackageLinkPopupClosed"
    />
    <vacant-room-popup
      v-if="isVacantRoomPopup"
      ref="vacantRoomPopup"
      @popupClosed="onVacantRoomPopupClosed"
    />
    <unlink-package-popup
      v-if="isUnlinkPackagePopup"
      ref="unlinkPackagePopup"
      @popupClosed="onUnlinkPackagePopupClosed"
    />
    <reservation-sms-send-popup
      v-if="isReservationSmsSendPopup"
      ref="reservationSmsSendPopup"
      @popupClosed="onReservationSmsSendPopupClosed"
    />
    <room-posting-popup
      v-if="isRoomPostingPopup"
      ref="roomPostingPopup"
      @popupClosed="onRoomPostingPopupClosed"
    />
    <pay-popup
      v-if="isPayPopupOpen"
      ref="payPopup"
      @popupClosed="payPopupClosed"
    />
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    />
    <deposit-use-receipt-popup
      v-if="isDepositUseReceiptPopupOpen"
      ref="depositUseReceiptPopup"
      @popupClosed="depositUseReceiptPopupClosed"
    />
    <re-issue-receipt-popup
      v-if="isReIssueReceiptPopupOpen"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClosed"
    />
    <room-ext-tr-popup
      v-if="isRoomExtTrPopupOpen"
      ref="roomExtTrPopup"
      @popupClosed="roomExtTrPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%}
body .appContent .body-article .article-right{width:1228px;margin:0 -1228px 0 0}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 1228px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{margin:0}
body .appContent .article-section.section-01 .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-02 .section-body{overflow-y:scroll}
body .appContent .article-section.section-02 .body-article .article-section{height:auto}
body .appContent .article-section.section-02 .body-article .section-header{width:auto}
body .appContent .article-section.section-02 .body-article .section-body{overflow:visible;border:none}

body .appContent .article-section.section-0201 .section-body .body-article.article-0101 {padding: 0;}
body .appContent .article-section.section-0201 .section-body .body-article.article-0101 .article-left {width: 43%;}
body .appContent .article-section.section-0201 .section-body .body-article.article-0101 .article-left .article-section.section-020101 {padding: 0;}
body .appContent .article-section.section-0201 .section-body .body-article.article-0101 .article-right {width: 57%;}
body .appContent .article-section.section-0201 .section-body .body-article.article-0101 .article-right .article-section.section-020102 {padding: 0 0 0 9px;}

body .appContent .article-section.section-02 .body-article .article-section.section-0202 {height: calc(100% - 213px);}
body .appContent .article-section.section-02 .body-article .article-section.section-0202 .section-body {border: 1px solid #ccc; background-color: #fff; border-top: none;}
body .appContent .article-section.section-02 .body-article .article-section.section-020201 {height: 100%;}
body .appContent .article-section.section-02 .body-article .article-section.section-020201 .section-body {border: none;}

body .appContent .article-section.section-020101 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-020101 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-020101 .body-data .field.groupName .content .item.input {width: calc(100% - 23px);}
body .appContent .article-section.section-020101 .body-data .field.golfPackageLink .content .item.text {width: calc(100% - 23px); text-align: center;}
body .appContent .article-section.section-020101 .body-data .field.golfPackageLink .content .item.text:hover {text-decoration: underline;}
body .appContent .article-section.section-020101 .body-data .field.grpName {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.grpName .content .item.input {width: 135px;}
body .appContent .article-section.section-020101 .body-data .field.grpName .content .item.select {width: 89px;}
body .appContent .article-section.section-020101 .body-data .field.reservationName .content .item.input {width: calc(100% - 23px);}

body .appContent .article-section.section-020102 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-020102 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-020102 .body-data .field.remarks {width: calc(100% + 1px);}
body .appContent .article-section.section-020102 .body-data .field.frontMemo {width: calc(100% + 1px);}
body .appContent .article-section.section-020102 .body-data .field.vipFlag {width: calc(100% + 1px);}
body .appContent .article-section.section-020102 .body-data .field.sendSMS {width: calc(100% + 1px);}
body .appContent .article-section.section-020102 .body-data .field.sendSMS .content .item.text-01 {width: 86px;}
body .appContent .article-section.section-020102 .body-data .field.sendSMS .content .item.text-02 {width: 43px;}
body .appContent .article-section.section-020102 .body-data .field.enrollment {width: calc(100% + 1px);}
body .appContent .article-section.section-020102 .body-data .field.enrollment .content .item {width: 129px;}

body .content-body .article-section.section-020201 .section-body >>> .e-grid.e-lib .dropdownbutton {margin: 0 -8px;}
body .content-body .article-section.section-020201 .section-body >>> .e-grid.e-lib .dropdownbutton .e-btn {
  overflow: hidden;box-shadow: none;width: 23px;height: 23px;padding: 0;border: none;border-radius: 0;background: transparent url(../../../assets/images/common/button-icon-etc.png) no-repeat center center;text-indent: -1000px;
}
</style>

<script>
import Vue from 'vue';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputDate from "@/components/common/datetime/InputDate";
import ComponentTelephone from "@/components/ComponentTelephone";
import MemberSelectPopup from "@/views/common/MemberSelectPopup";
import GroupPopup from "@/views/golf-reservation/popup/GroupPopup";
import AvailableRoomPopup from "@/views/room-reservation-management/popup/AvailableRoomPopup";
import RoomRateDetailsByDatePopup from "@/views/room-reservation-management/popup/RoomRateDetailsByDatePopup";
import RoomReservationCancelPopup from "@/views/room-reservation-management/popup/RoomReservationCancelPopup";
import GolfPackageLinkPopup from "@/views/room-reservation-management/popup/GolfPackageLinkPopup";
import VacantRoomPopup from "@/views/room-reservation-management/popup/VacantRoomPopup";
import UnlinkPackagePopup from "@/views/room-reservation-management/popup/UnlinkPackagePopup";
import ReservationSmsSendPopup from "@/views/golf-reservation/popup/ReservationSmsSendPopup";
import RoomReservationRegistrationSearchDetailPopup from "@/views/room-reservation-management/room-reservation/popup/RoomReservationRegistrationSearchDetailPopup";
import RoomPostingPopup from "@/views/room-front/popup/RoomPostingPopup";
import PayPopup from "@/views/common/PayPopup";
import PayCancelPopup from "@/components/popups/PayCancelPopup";
import DepositUseReceiptPopup from "@/views/credit-management/popup/DepositUseReceiptPopup";
import ReIssueReceiptPopup from "@/components/popups/ReIssueReceiptPopup";
import RoomExtTrPopup from "@/views/room-front/popup/RoomExtTrPopup";
import ErpButton from "@/components/button/ErpButton.vue";

import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize, Aggregate, Group} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate, getTodayDate, getDateOfNext, getDayOfWeekCaption, compareDate,} from "@/utils/date";
import { formPreventEnterEvent, validateFormRefs, } from "@/utils/formUtil";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetComName,
  commonCodesGetJsonData,
  commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import { memberNoFormatter } from "@/utils/formatter";
import {getGroupList} from "@/api/group";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import moment from "moment";
import {deepDiffs} from "@/utils/ObjectUtil";
import { orderBy as _orderBy, maxBy as _maxBy, minBy as _minBy } from "lodash";
import {mapActions, mapState} from "vuex";
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';
import {numberWithCommas} from "@/utils/number";

MultiSelect.Inject(CheckBoxSelection);

const TAB_BY_ROOM_DETAIL = {
  CHECKIN: {
    HEADER: "Check-In",
    DIV: "CHECKIN",
  },
  CHECKOUT: {
    HEADER: "Check-Out",
    DIV: "CHECKOUT",
  },
  CASHIER: {
    HEADER: "Cashier",
    DIV: "CASHIER",
  },
  SALES: {
    HEADER: "Sales",
    DIV: "SALES",
  },
  CREDIT: {
    HEADER: "Credit",
    DIV: "CREDIT",
  },
};

export default {
  name: "RoomCheckIn",
  components: {
    EjsGridWrapper,
    InputText,
    InputTextarea,
    InputDate,
    ComponentTelephone,
    MemberSelectPopup,
    GroupPopup,
    AvailableRoomPopup,
    RoomRateDetailsByDatePopup,
    RoomReservationCancelPopup,
    GolfPackageLinkPopup,
    VacantRoomPopup,
    UnlinkPackagePopup,
    ReservationSmsSendPopup,
    RoomReservationRegistrationSearchDetailPopup,
    RoomPostingPopup,
    PayPopup,
    PayCancelPopup,
    DepositUseReceiptPopup,
    ReIssueReceiptPopup,
    RoomExtTrPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      gridEjsDropdownlistEditTemplateEventBus: new Vue(),
      selectStayId: null,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      isAvailableRoomPopup: false,
      isRoomRateDetailsByDatePopup: false,
      isRoomReservationCancelPopup: false,
      isGolfPackageLinkPopup: false,
      isVacantRoomPopup: false,
      isUnlinkPackagePopup: false,
      isReservationSmsSendPopup: false,
      isRoomPostingPopup: false,
      isPayPopupOpen: false,
      isPayCancelPopupOpen: false,
      isReIssueReceiptPopupOpen: false,
      isRoomExtTrPopupOpen: false,
      isDepositUseReceiptPopupOpen: false,
      memberPopupPosition: { x: "center", y: "center" },
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
        GROUP_NAME: "GROUP_NAME", // 그룹명(객실)
      },
      searchOptions: {
        groupName: null,
        resveName: null,
        guestName: null,
        bsnDate: getFormattedDate(new Date()),
        isExcludResveCancel: true,
        rresveNo: null,
        rresveStatusList: [],
        rresveStatusCodes: commonCodesGetCommonCode("RRESVE_STATUS", true),
        isDepartureDateUse: false,
        departureDateRangeValue: { to: null, from: null },
        isResveDateUse: false,
        resveDateRangeValue: { to: null, from: null },
        resveKind: "ALL",
        resveKindCodes: [],
        resveChannel: "ALL",
        resveChannelCodes: [],
        roomType: "ALL",
        roomTypeCodes: [],
        memberDiv: "ALL",
        memberDivCodes: [],
        memberGrade: "ALL",
        memberGradeCodes: [],
        roomSaleKind: "ALL",
        roomSaleKindCodes: [],
        roomDcKind: "ALL",
        roomDcKindCodes: [],
        isGolfPackage: false,
        searchDiv: "EXPECT",
        searchDivCodes: [
          {
            comCode: "ALL",
            comName: "전체",
          },
          {
            comCode: "EXPECT",
            comName: "도착예정",
          },
          {
            comCode: "STAY",
            comName: "투숙",
          },
          {
            comCode: "CHECKOUT",
            comName: "퇴실예정",
          },
        ],
        isExcludCreditCancel: true,
        isExcludSalesCancel: true,
        salesGroupDiv: "DATE",
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      isRoomResveDetailViewOpened: false,
      roomReservations: [],
      roomReservationsCount: 0,
      isSearchDetailPopupOpen: false,
      orgRoomReservationDetail: {
        reservationInfo: {
          rresveNo: null,
          resveDate: null,
          groupName: null,
          golfPackageLink: null,
          grpNo: null,
          grpName: null,
          grpKind: null,
          resveName: null,
          membershipId: null,
          memberNo: null,
          memberDiv: null,
          memberGrade: null,
          contactName: null,
          contactTel: null,
          resveKind: null,
          resveChannel: null,
          areaCode: null,
          sexCode: null,
          resveRemarks: null,
          frontMemo: null,
          vipFlag: null,
          smsSendFlag: null,
          smsSendCnt: null,
          smsSendDt: null,
          insertName: null,
          insertDt: null,
        },
      },
      roomReservationDetail: {
        reservationInfo: {
          rresveNo: null,
          resveDate: null,
          groupName: null,
          golfPackageLink: null,
          grpNo: null,
          grpName: null,
          grpKind: null,
          resveName: null,
          membershipId: null,
          memberNo: null,
          memberDiv: null,
          memberGrade: null,
          contactName: null,
          contactTel: null,
          resveKind: null,
          resveChannel: null,
          areaCode: null,
          sexCode: null,
          resveRemarks: null,
          frontMemo: null,
          vipFlag: null,
          smsSendFlag: null,
          smsSendCnt: null,
          smsSendDt: null,
          insertName: null,
          insertDt: null,
        },
        reservationDetails: [],
        reservationDetailsCount: 0,
        cashierList: [],
        cashierListCount: 0,
        salesList: [],
        checkoutList: [],
        checkoutListCount: 0,
        creditList: [],
      },
      roomReservationViewOptions: {
        sexCodeOptions: commonCodesGetCommonCode("SEX_CODE", true),
        resveChannelOptions: commonCodesGetCommonCode("RESVE_CHANNEL", true),
        resveKindOptions: commonCodesGetCommonCode("RESVE_KIND", true),
        memberGradeOptions: commonCodesGetCommonCode("MEMBER_GRADE", true),
        memberDivOptions: commonCodesGetCommonCode("MEMBER_DIV", true),
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND", true),
        areaCodeOptions: commonCodesGetCommonCode("AREA_CODE", true),
      },
      roomReservationInfoValidationRules: {
        groupName: {
          required: true,
        },
        resveName: {
          required: true,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        contactName: {
          required: true,
        },
      },
      selectTablDiv: "CHECKIN",
    };
  },
  created() {
    this.searchOptions.departureDateRangeValue.from = getFormattedDate(new Date());
    this.searchOptions.departureDateRangeValue.to = getFormattedDate(new Date());
    this.searchOptions.resveDateRangeValue.from = getFormattedDate(new Date());
    this.searchOptions.resveDateRangeValue.to = getFormattedDate(new Date());

    this.searchOptions.resveKindCodes = commonCodesGetCommonCode("RESVE_KIND", true);
    if (
      this.searchOptions.resveKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.resveKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.resveChannelCodes = commonCodesGetCommonCode("RESVE_CHANNEL", true);
    if (
      this.searchOptions.resveChannelCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.resveChannelCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomTypeCodes = commonCodesGetCommonCode("ROOM_TYPE", true);
    if (
      this.searchOptions.roomTypeCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomTypeCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.memberDivCodes = commonCodesGetCommonCode("MEMBER_DIV", true);
    if (
      this.searchOptions.memberDivCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.memberDivCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.memberGradeCodes = commonCodesGetCommonCode("MEMBER_GRADE", true);
    if (
      this.searchOptions.memberGradeCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.memberGradeCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomSaleKindCodes = commonCodesGetCommonCode("ROOM_SALE_KIND", true);
    if (
      this.searchOptions.roomSaleKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomSaleKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomDcKindCodes = commonCodesGetCommonCode("ROOM_DC_KIND", true);
    if (
      this.searchOptions.roomDcKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomDcKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.$EventBus.$on("memoConfirm", (args) => {
      this.memoConfirm(args);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("memoConfirm");
  },
  computed: {
    ...mapState("memoView", [
      "memoStayId",
    ]),
    bsnDateTitle() {
      return this.searchOptions.searchDiv === "CHECKOUT" ? "퇴실일자" : this.searchOptions.searchDiv === "STAY" ? "재실일자" : "도착일자";
    },
    salesList() {
      let salesDate = null;

      return this.roomReservationDetail.salesList.filter(item => this.searchOptions.isExcludSalesCancel ? !item.isDeleted : true).map(item => {
        const isView = salesDate !== item.salesDate;
        salesDate = item.salesDate;
        return ({
          ...item,
          isView: isView,
        });
      }) || [];
    },
    salesListCount() {
      return this.roomReservationDetail.salesList.filter(item => this.searchOptions.isExcludSalesCancel ? !item.isDeleted : true).length || 0;
    },
    creditList() {
      return this.roomReservationDetail.creditList.filter(item => this.searchOptions.isExcludCreditCancel ? !item.isDeleted : true);
    },
    creditListCount() {
      return this.roomReservationDetail.creditList.filter(item => this.searchOptions.isExcludCreditCancel ? !item.isDeleted : true).length || 0;
    },
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen ||
        this.isAvailableRoomPopup ||
        this.isRoomRateDetailsByDatePopup ||
        this.isRoomReservationCancelPopup ||
        this.isGolfPackageLinkPopup ||
        this.isVacantRoomPopup ||
        this.isUnlinkPackagePopup ||
        this.isReservationSmsSendPopup ||
        this.isSearchDetailPopupOpen ||
        this.isRoomPostingPopup ||
        this.isPayPopupOpen ||
        this.isPayCancelPopupOpen ||
        this.isReIssueReceiptPopupOpen ||
        this.isDepositUseReceiptPopupOpen ||
        this.isRoomExtTrPopupOpen
      );
    },
    availableRoomButtonProps() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.availableRoom",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
      };
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
    tabItems() {
      return Object.values(TAB_BY_ROOM_DETAIL).map(item => {
        return {
          header: {
            text: item.HEADER,
          },
        };
      });
    },
    roomResveGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "stayId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "groupName",
            headerText: "그룹명",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "resveName",
            headerText: "예약자",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "rresveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RRESVE_STATUS",
          },
          {
            field: "memo",
            headerText: "",
            type: "string",
            minWidth: 16,
            maxWidth: 20,
            width: 20,
            allowEditing: false,
            allowFiltering: false,
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "stayCnt",
            headerText: "박수",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            field: "resveContactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "rresveNo",
            headerText: "예약번호",
            type: "string",
            minWidth: 16,
            width: 100,
          },
          {
            field: "golfResve",
            headerText: "골프예약",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_SALE_KIND",
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "vipFlag",
            headerText: "VIP",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "resveKind",
            headerText: "예약종류",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_KIND",
          },
          {
            field: "resveChannel",
            headerText: "예약채널",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_CHANNEL",
          },
          {
            field: "resveRemarks",
            headerText: "예약비고",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "frontMemo",
            headerText: "프론트메모",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
        ],
      };
    },
    roomResveDetailGridOptions() {
      return {
        provides: [
          Edit,
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        noColumnWidth: 40,
        isSelectedRowRetain: false,
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        columns: [
          {
            field: "orgRoomNo",
            headerText: "ROOM",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "reprsntRoomFlag",
            headerText: "대표",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "frpyAbleFlag",
            headerText: "후불",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
            allowEditing: false,
          },
          {
            field: "stayCnt",
            headerText: "박",
            type: "number",
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 50,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
            allowEditing: false,
          },
          {
            field: "memo",
            headerText: "",
            type: "string",
            minWidth: 16,
            maxWidth: 20,
            width: 20,
            allowEditing: false,
            allowFiltering: false,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
            editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                "roomType",
                commonCodesGetCommonCode("ROOM_TYPE"),
                this.$refs.roomResveDetailGrid,
                this.gridEjsDropdownlistEditTemplateEventBus,
              ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                'ROOM_TYPE',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "vacantRoomIcon",
            headerText: "",
            type: "string",
            minWidth: 16,
            width: 25,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "rresveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RRESVE_STATUS",
            allowEditing: false,
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                "roomSaleKind",
                commonCodesGetCommonCode("ROOM_SALE_KIND"),
                this.$refs.roomResveDetailGrid,
                this.gridEjsDropdownlistEditTemplateEventBus,
              ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                'ROOM_SALE_KIND',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "dcContents",
            headerText: "할인내용",
            type: "string",
            minWidth: 16,
            width: 150,
          },
          {
            field: "vehicleNo",
            headerText: "차량번호",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "contactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            editType: "telephoneedit",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "adultCnt",
            headerText: "성인",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "childCnt",
            headerText: "소아",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "roomId",
            type: "string",
            visible: false,
          },
        ],
        validationModification: false,
        validationRules: {
          guestName: {
            required: true,
            maxLength: 100,
          },
          arrivalDate: {
            required: true,
            dateFormat: true,
          },
          stayCnt: {
            required: true,
          },
          departureDate: {
            required: true,
            dateFormat: true,
          },
          roomType: {
            required: true,
          },
        },
      };
    },
    roomCheckoutGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        noColumnWidth: 40,
        columns: [
          {
            field: "stayId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "center",
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "stayCnt",
            headerText: "박",
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "totAmt",
            headerText: "이용총액",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payCard",
            headerText: "카드",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payCash",
            headerText: "현금",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payCredit",
            headerText: "미수",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payDeposit",
            headerText: "선수/선불",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payGiftAndTrans",
            headerText: "상품/계좌",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payGolfCarryOver",
            headerText: "골프이월",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "payRoomCarryOver",
            headerText: "객실이월",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "noPayAmt",
            headerText: "미정산액",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 75,
            textAlign: "right",
          },
          {
            field: "checkoutTime",
            headerText: "C/O",
            type: "string",
            textAlign: "center",
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "guestName",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "totAmt",
                aggregationType: "TotalSum",
              },
              {
                field: "payCard",
                aggregationType: "TotalSum",
              },
              {
                field: "payCash",
                aggregationType: "TotalSum",
              },
              {
                field: "payCredit",
                aggregationType: "TotalSum",
              },
              {
                field: "payDeposit",
                aggregationType: "TotalSum",
              },
              {
                field: "payGiftAndTrans",
                aggregationType: "TotalSum",
              },
              {
                field: "payGolfCarryOver",
                aggregationType: "TotalSum",
              },
              {
                field: "payRoomCarryOver",
                aggregationType: "TotalSum",
              },
              {
                field: "noPayAmt",
                aggregationType: "TotalSum",
              },
            ],
          },
        ],
      };
    },
    roomCashierGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        noColumnWidth: 40,
        columns: [
          {
            field: "slipId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "salesDate",
            headerText: "매출일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "productName",
            headerText: "매출구분",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "payDiv",
            headerText: "입금구분",
            type: "string",
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "PAY_DIV",
            textAlign: "center",
          },
          {
            field: "b",
            headerText: "입금종류",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "c",
            headerText: "거래처",
            type: "string",
            minWidth: 16,
            width: 120,
          },
          {
            field: "d",
            headerText: "카드/성명",
            type: "string",
            minWidth: 16,
            width: 120,
          },
          {
            field: "totAmt",
            headerText: "CHARGE",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "payAmt",
            headerText: "CREDIT",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            // minWidth: 16,
            // width: 210,
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "productName",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "totAmt",
                aggregationType: "TotalCaption",
                customAggregate: ({result}) => {
                  const resultFilter = result.filter(item => !item.isDeleted);
                  return resultFilter.length > 0 ? resultFilter.map(item => item.totAmt).reduce((acc, cur) => acc + cur) : 0;
                },
              },
              {
                field: "payAmt",
                aggregationType: "TotalCaption",
                customAggregate: ({result}) => {
                  const resultFilter = result.filter(item => !item.isDeleted);
                  return resultFilter.length > 0 ? resultFilter.map(item => item.payAmt).reduce((acc, cur) => acc + cur) : 0;
                },
              },
            ],
          },
        ],
      };
    },
    salesGroupColumns() {
      return this.searchOptions.salesGroupDiv === "STORE" ? ["groupByStoreCode"] : ["groupBySalesDate"];
    },
    roomSalesGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        groupSettings: {
          columns: this.salesGroupColumns,
          showDropArea: false,
        },
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        allowGrouping: true,
        noColumnWidth: 40,
        columns: [
          {
            field: "slipId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "groupBySalesDate",
            type: "string",
            visible: false,
          },
          {
            field: "groupByStoreCode",
            type: "string",
            visible: false,
          },
          {
            field: "salesDate",
            headerText: "매출일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "STORE_CODE",
            allowEditing: false,
          },
          {
            field: "productName",
            headerText: "상품명",
            type: "string",
            minWidth: 16,
            width: 120,
          },
          {
            field: "className",
            headerText: "상품분류",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "salePrice",
            headerText: "단가",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "saleQty",
            headerText: "수량",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 60,
            textAlign: "right",
          },
          {
            field: "totAmt",
            headerText: "총액",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "isPay",
            headerText: "정산",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "insertTime",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "payerRoomNo",
            headerText: "지불객실",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 210,
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "productName",
                aggregationType: "GroupCaption",
                customAggregate: (this.searchOptions.salesGroupDiv === "STORE" ? "매장별" : "일자별").concat(" 소계"),
              },
              {
                field: "totAmt",
                aggregationType: "GroupCaption",
                customAggregate: ({items}) => {
                  const itemsFilter = items.filter(item => !item.isDeleted);
                  return itemsFilter.length > 0 ? itemsFilter.map(item => item.totAmt).reduce((acc, cur) => acc + cur) : 0;
                },
              },
            ],
          },
          {
            columns: [
              {
                field: "productName",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "totAmt",
                aggregationType: "TotalCaption",
                customAggregate: ({
                  result: {
                    records
                  }
                }) => {
                  const recordsFilter = records.filter(item => !item.isDeleted);
                  return recordsFilter.length > 0 ? recordsFilter.map(item => item.totAmt).reduce((acc, cur) => acc + cur) : 0;
                },
              },
            ],
          },
        ],
      };
    },
    roomCreditGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Aggregate,
          Group,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        noColumnWidth: 40,
        columns: [
          {
            field: "payId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "STORE_CODE",
            textAlign: "center",
          },
          {
            field: "prpayFrpyDiv",
            headerText: "선/후불",
            type: "string",
            minWidth: 16,
            width: 60,
            isCommonCodeField: true,
            groupCode: "PRPAY_FRPY_DIV",
            textAlign: "center",
          },
          {
            field: "payDate",
            headerText: "정산일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "payDiv",
            headerText: "정산구분",
            type: "string",
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "PAY_DIV",
            textAlign: "center",
          },
          {
            field: "payStatus",
            headerText: "상태",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "center",
          },
          {
            field: "purcName",
            headerText: "카드사",
            type: "string",
            minWidth: 16,
            width: 120,
          },
          {
            field: "approvalDivName",
            headerText: "승인상태",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "approvalNo",
            headerText: "승인번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "cardNo",
            headerText: "카드번호",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "payAmt",
            headerText: "정산금액",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "dropdownMenu",
            headerText: "",
            type: "string",
            minWidth: 16,
            width: 24,
            template: () => {
              return {
                template: {
                  extends: Vue.component("payInfoGridDropdownTemplate", {
                    template: "<div class='dropdownbutton' style=''><ejs-dropdownbutton :items='menuItems' :select='onMenuSelect'></ejs-dropdownbutton></div>",
                    props: ["roomCreditGridMenuSelectedCallback"],
                    data() {
                      return {
                        menuItems: [],
                        data: {
                          data: {},
                        },
                      };
                    },
                    mounted() {
                      this.menuItems = !this.data.isDeleted ?
                        [
                          {
                            text: "정산취소",
                            value: "CANCEL",
                          },
                          {
                            text: "영수증 재발행",
                            value: "REISSUE",
                          },
                        ] :
                        [
                          {
                            text: "영수증 재발행",
                            value: "REISSUE",
                          },
                        ]
                      ;
                    },
                    methods: {
                      onMenuSelect: function (args) {
                        let eventParam = {
                          menu: args.item.value,
                          data: this.data,
                        };
                        this.roomCreditGridMenuSelectedCallback(eventParam);
                      },
                    },
                  }),
                  propsData: {
                    roomCreditGridMenuSelectedCallback: this.onRoomCreditGridMenuSelected
                  },
                },
              };
            }
          },
          {
            field: "insertUserName",
            headerText: "정산자",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "insertDt",
            headerText: "정산일시",
            type: "string",
            minWidth: 16,
            width: 130,
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            // minWidth: 16,
            // width: 210,
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "guestName",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "payAmt",
                aggregationType: "TotalCaption",
                customAggregate: ({result}) => {
                  const resultFilter = result.filter(item => !item.isDeleted);
                  return resultFilter.length > 0 ? resultFilter.map(item => item.payAmt).reduce((acc, cur) => acc + cur) : 0;
                },
              },
            ],
          },
        ],
      };
    },
  },
  async mounted() {
    if (
      this.$route.query?.arrivalDate &&
      this.$route.query?.roomType
    ) {
      this.onAddButtonClicked(null, {
        arrivalDate: this.$route.query.arrivalDate,
        roomType: this.$route.query.roomType,
      });
    } else if(
      this.$route.query?.arrivalDate &&
      this.$route.query?.rresveNo
    ) {
      this.searchOptions.searchDiv = "ALL";
      this.searchOptions.bsnDate = this.$route.query.arrivalDate;
      await this.fetchRoomCheckIns();
      await this.getRoomReservationDetail(this.$route.query?.rresveNo);
    }
  },
  methods: {
    ...mapActions("memoView", ["setStayId", "clearMemo"]),
    numberWithCommas,
    memberNoFormatter,
    formPreventEnterEvent,
    validateFormRefs,
    async viewButtonClicked() {
      await this.fetchRoomCheckIns();

      if (this.roomReservationDetail.reservationInfo.rresveNo) {
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    async fetchRoomCheckIns() {
      const data =
        await GolfErpAPI.fetchRoomCheckIns({
          bsnDate: this.searchOptions.bsnDate,
          searchDiv: this.searchOptions.searchDiv === "ALL" ? null : this.searchOptions.searchDiv,
          groupName: this.searchOptions.groupName,
          resveName: this.searchOptions.resveName,
          isExcludResveCancel: this.searchOptions.isExcludResveCancel,
          rresveNo: this.searchOptions.rresveNo,
          rresveStatusList: this.searchOptions.rresveStatusList,
          departureDateFrom: !this.searchOptions.isDepartureDateUse ? null : this.searchOptions.departureDateRangeValue.from,
          departureDateTo: !this.searchOptions.isDepartureDateUse ? null : this.searchOptions.departureDateRangeValue.to,
          resveDateFrom: !this.searchOptions.isResveDateUse ? null : this.searchOptions.resveDateRangeValue.from,
          resveDateTo: !this.searchOptions.isResveDateUse ? null : this.searchOptions.resveDateRangeValue.to,
          resveKind: this.searchOptions.resveKind === "ALL" ? null : this.searchOptions.resveKind,
          resveChannel: this.searchOptions.resveChannel === "ALL" ? null : this.searchOptions.resveChannel,
          roomType: this.searchOptions.roomType === "ALL" ? null : this.searchOptions.roomType,
          memberDiv: this.searchOptions.memberDiv === "ALL" ? null : this.searchOptions.memberDiv,
          memberGrade: this.searchOptions.memberGrade === "ALL" ? null : this.searchOptions.memberGrade,
          roomSaleKind: this.searchOptions.roomSaleKind === "ALL" ? null : this.searchOptions.roomSaleKind,
          roomDcKind: this.searchOptions.roomDcKind === "ALL" ? null : this.searchOptions.roomDcKind,
          isGolfPackage : this.searchOptions.isGolfPackage,
        });

      this.roomReservations =
        _orderBy(
          data.map(item => ({
            ...item,
            groupName: item.trResveMember.groupName,
            resveName: item.trResveMember.resveName,
            memberDiv: item.trResveMember.memberDiv,
            memberGrade: item.trResveMember.memberGrade,
            resveContactTel: item.trResveMember.contactTel,
            resveDate: item.trResveMember.resveDate,
            grpName: item.trResveMember.grpName,
            vipFlag: item.trResveMember.vipFlag,
            resveKind: item.trResveMember.resveKind,
            resveChannel: item.trResveMember.resveChannel,
            resveRemarks: item.trResveMember.resveRemarks,
            frontMemo: item.trResveMember.frontMemo,
            roomAmt: item?.trResveStayPaymts?.map(item => item.roomAmt).reduce((acc, cur) => acc + cur, 0),
            isNewMemo: (item?.memoReceivedCount || 0) > (item?.memoConfirmCount || 0),
            golfResve: (item.trResveMember?.trResveGroupMaps?.filter(groupMap => (groupMap?.tgResveConfirms?.length || 0) > 0).length || 0) > 0 ? "√" : null,
          })), ["arrivalDate", "departureDate", "groupName", "resveName"]);
      this.roomReservationsCount = this.roomReservations.length;
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
    },
    onAvailableRoomButtonClicked() {
      this.isAvailableRoomPopup = true;

      this.$nextTick(() => {
        this.$refs.availableRoomPopup.showPopup({
          bsnDate: moment().format("YYYY-MM-DD"),
        });
      });
    },
    async onRoomReservationCancelButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 예약 상세가 존재하지 않습니다");
        return;
      }

      if (selectedRecords[0].rresveStatus === "CANCEL") {
        this.errorToast("이미 취소된 객실입니다");
        return;
      }

      if (selectedRecords[0].stayId) {
        this.isRoomReservationCancelPopup = true;

        this.$nextTick(() => {
          this.$refs.roomReservationCancelPopup.showPopup({
            rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
            stayIds: selectedRecords.map(record => record.stayId),
          });
        });
      } else {
        const {
          _rid,
        } = selectedRecords[0];

        const records = grid.getBatchCurrentViewRecords();
        const reprsntRoomFlag = _maxBy(records.filter(item => item._rid === _rid).map(item => item.reprsntRoomFlag));
        if (reprsntRoomFlag) {
          this.$refs.roomResveDetailGrid.updateCell(0, "reprsntRoomFlag", true);
          this.$refs.roomResveDetailGrid.updateCell(0, "frpyAbleFlag", true);
        }
        grid.deleteRecord();
      }
    },
    async onAddButtonClicked(event, args) {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isRoomResveDetailViewOpened = true;
      this.reservationInfoInit();
      // this.setReservationDefaultInfo();
      this.$refs.tabByRoomDetail.select(0);

      this.$nextTick(() => {
        setTimeout(() => {
          if (args) {
            this.onRoomDetailAddButtonClicked(null, args);
          }
          this.$refs.groupName.focus();
        }, 500);
      });
    },
    onClickExcel() {
      this.$refs.roomResveGrid.excelExport();
    },
    onRoomResveGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        },
        node: {
          classList,
        }
      } = args;

      if (["resveName", "groupName"].includes(field)) {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      } else if (field === "memo") {
        classList.add(this.$t("className.grid.memoArea"));
      }
    },
    onRoomResveGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell: {
          classList,
          style,
        },
        data,
      } = args;

      if (data.rresveStatus === "CANCEL") {
        style.textDecoration = "line-through";
      }

      if (["resveName", "groupName"].includes(field)) {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      } else if (field === "memo") {
        if (data.memoAllCount > 0) {
          if (data.isNewMemo && commonCodesGetStandardInfo("memoConfirmFlag")) {
            classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field === "roomNo") {
        style.fontWeight = "bold";
      } else if (field === "rresveStatus") {
        style.color = commonCodesGetColorValue("RRESVE_STATUS", data["rresveStatus"]);
      }
    },
    async onRoomResveGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      if (["resveName", "groupName", "memo"].includes(field)) {
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        this.selectStayId = rowData["stayId"];

        this.getRoomReservationDetail(rowData["rresveNo"]);

        if (field === "memo" || rowData.memoAllCount > 0) {
          this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
        }
      }
    },
    async onRoomResveGridRowSelected(args) {
      const {
        data,
      } = args;

      if (data.stayId) {
        this.selectStayId = data.stayId;
        await this.setStayId(data.stayId);
      }
    },
    onRoomResveGridActionComplete(args) {
      const {
        requestType,
        action
      } = args;

      if (requestType === "filtering") {
        if (action === "clearFilter") {
          this.roomReservationsCount = this.roomReservations.length;
        } else if ((args?.rows?.length || 0) > 0) {
          const filteredRecords = this.$refs.roomResveGrid.getFilteredRecords();
          this.roomReservationsCount = filteredRecords.length || 0;
        } else {
          this.roomReservationsCount = 0;
        }
      }
    },
    onInitButtonClicked() {
      this.searchOptions.rresveNo = null;
      this.searchOptions.rresveStatusList = [];
      this.searchOptions.isDepartureDateUse = false;
      this.searchOptions.isResveDateUse = false;
      this.searchOptions.departureDateRangeValue.from = getFormattedDate(new Date());
      this.searchOptions.departureDateRangeValue.to = getFormattedDate(new Date());
      this.searchOptions.resveDateRangeValue.from = getFormattedDate(new Date());
      this.searchOptions.resveDateRangeValue.to = getFormattedDate(new Date());
      this.searchOptions.resveKind = "ALL";
      this.searchOptions.resveChannel = "ALL";
      this.searchOptions.roomType = "ALL";
      this.searchOptions.memberDiv = "ALL";
      this.searchOptions.memberGrade = "ALL";
      this.searchOptions.roomSaleKind = "ALL";
      this.searchOptions.roomDcKind = "ALL";
      this.searchOptions.isGolfPackage = false;
    },
    onSearchDetailPopupClose() {
      this.isSearchDetailPopupOpen = false;
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.roomReservationInfoValidationRules)) {
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      if (!grid.validate()) {
        return;
      }

      const records = grid.getBatchCurrentViewRecords();

      if (!(records.length > 0)) {
        this.errorToast("객실이 존재하지 않습니다");
        return;
      }

      if (!(records.filter(item => item.roomNo).length > 0)) {
        this.errorToast("객실배정 후 체크인 저장 바랍니다");
        return;
      }

      if (
        !this.isReservationDetailChanged() &&
        !(records.filter(item => item.rresveStatus === "NAMING").length > 0)
      ) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      if (records.filter(item => item.reprsntRoomFlag && item.rresveStatus === "CANCEL").length > 0) {
        this.errorToast("취소 객실은 대표 객실이 될 수 없습니다");
        return;
      } else if (!(records.filter(item => item.reprsntRoomFlag).length > 0)) {
        this.errorToast("대표 객실이 존재하지 않습니다");
        return;
      } else if (records.filter(item => item.reprsntRoomFlag).length > 1) {
        this.errorToast("대표 객실은 반드시 1건만 선택되어야 합니다");
        return;
      }

      const resveMember =
        this.roomReservationDetail.reservationInfo.rresveNo ?
          deepDiffs(this.orgRoomReservationDetail.reservationInfo, this.roomReservationDetail.reservationInfo) :
          this.roomReservationDetail.reservationInfo
      ;

      if (resveMember) {
        resveMember.rresveNo = this.roomReservationDetail.reservationInfo.rresveNo;
        if (
          !!resveMember.rresveNo &&
          !!resveMember.memberDiv &&
          resveMember.memberDiv === "NOM"
        ) {
          resveMember.membershipId = "";
          resveMember.memberNo = "";
        }
      }

      const {
        addedRecords,
        changedRecords,
      } = grid.patchBatchChanges();
      const resveStays = [];
      addedRecords.forEach(item => {
        resveStays.push(item);
      });
      changedRecords.forEach(item => {
        resveStays.push(({
          ...item,
          stayId: records.find(record => record._rid === item._rid)?.stayId,
        }));
      });

      const namingRooms = records.filter(item => item.rresveStatus === "NAMING");

      if (namingRooms.length > 0) {
        namingRooms.forEach(item => {
          const findIndex = resveStays.findIndex(item2 => item2.stayId === item.stayId);
          if (findIndex >= 0) {
            resveStays[findIndex].roomId = item.roomId;
            resveStays[findIndex].roomNo = item.roomNo;
          } else {
            resveStays.push({
              stayId: item.stayId,
              roomId: item.roomId,
              roomNo: item.roomNo,
            });
          }
        });
      }

      const notCheckIns = records.filter(item => !["CHECKIN","CHECKOUT"].includes(item.rresveStatus)).map(item => item.stayId);

      /*
           1. 최초 조회시 대표객실이 없는 경우 대표와 후불 항목에 체크를 해서 수정된 데이터로 인식됨.
           2. 그로 인해 객실번호가 없어 저장전 체크 항목에 걸리게 됨.
           3. 해결방법은 객실번호가 없는 예약 중 대표 항목이 체크되어있으면 객실번호 체크에 안걸리게 함.
      */
      const notRoomNo = resveStays.filter(item => notCheckIns.includes(item.stayId) && !item.roomNo);
      if (notRoomNo.filter(item => item?.reprsntRoomFlag === undefined).length > 0) {
        this.errorToast("객실배정 후 체크인 저장 바랍니다");
        return;
      }

      const rresveNo = await GolfErpAPI.roomCheckInDetailSave({
        resveMember: resveMember,
        resveStays: resveStays,
        rresveNo: this.roomReservationDetail.reservationInfo.rresveNo ? this.roomReservationDetail.reservationInfo.rresveNo : null,
      });

      this.infoToast("저장되었습니다");

      // 자동 SMS 발송 보류.
      // if (addedRecords.length > 0) {
      //   if (await this.confirm("예약 SMS를 발송하시겠습니까?")) {
      //     await this.autoSendSms(addedRecords, rresveNo);
      //   }
      // }

      await this.fetchRoomCheckIns();
      await this.getRoomReservationDetail(rresveNo);

      const selectedRecords = grid.getSelectedRecords();
      if (selectedRecords.length > 0 && selectedRecords[0].stayId) {
        await this.setStayId(selectedRecords[0].stayId);
      }
    },
    setSendSmsData(rresveNo, record) {
      const roomReservationDetailReservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));
      return new Promise(function (resolve) {
        let smsSendList = [];
        let sendData = {};

        sendData.sendKey = rresveNo;
        sendData.name =
          roomReservationDetailReservationInfo.resveName +
          " " +
          commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
            "MEMBER_DIV",
            roomReservationDetailReservationInfo.memberDiv,
            1
          )
        ;
        sendData.resveNo = rresveNo;
        if (record) {
          sendData.roomType = commonCodesGetComName("ROOM_TYPE", record.roomType);
          sendData.resveDate = record.arrivalDate;
          sendData.dwName = getDayOfWeekCaption(record.arrivalDate);
          sendData.night = record.stayCnt;
        }

        const smsRecptnDiv = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.smsRecptnDiv || "Y";

        if (smsRecptnDiv === "A") {
          if (roomReservationDetailReservationInfo.contactTel) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (smsRecptnDiv === "B") {
          if (roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else {
          if (roomReservationDetailReservationInfo.contactTel) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }

        resolve(smsSendList);
      });
    },
    async autoSendSms(records, rresveNo) {
      let smsSendList = [];
      await this.setSendSmsData(rresveNo)
        .then((response) => {
          smsSendList = response;
        })
        .catch((error) => {
          console.log("setSendSmsData.===>", error);
        });

      // SMS 전송할 데이터 없으면 RETURN.
      if (!(smsSendList.length > 0)) {
        this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
        return;
      }

      const smsSendInfo = {
        type: "R_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        records.forEach(record => {
          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoDispatchKey
              : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoTemplateCode
              : null,
            key: data.sendKey,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.receiverName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            message: message,
            replaceDataMap: {
              name: data.name,
              roomType: commonCodesGetComName("ROOM_TYPE", record.roomType),
              resveDate: record.arrivalDate,
              dwName: getDayOfWeekCaption(record.arrivalDate),
              night: record.stayCnt,
              resveNo: rresveNo,
              headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : "") : ""
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
          };

          smsRequestList.push(smsInfo);
        });
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    onCancelButtonClicked() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        this.errorToast("예약번호가 존재하지 않습니다");
        return;
      }

      this.isRoomReservationCancelPopup = true;

      this.$nextTick(() => {
        this.$refs.roomReservationCancelPopup.showPopup({
          rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
        });
      });
    },
    async sendSMS() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        this.errorToast("SMS 전송할 자료가 존재하지 않습니다");
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("SMS 전송할 객실 예약 정보를 선택해 주시기 바랍니다");
        return;
      }

      if (selectedRecords.filter(item => item.rresveStatus !== "RESVE").length > 0) {
        this.errorToast("예약상태 [예약]만 SMS 전송이 가능합니다");
        return;
      }

      let smsSendList = [];
      await this.setSendSmsData(this.roomReservationDetail.reservationInfo.rresveNo, selectedRecords[0])
        .then((response) => {
          smsSendList = response;
        })
        .catch((error) => {
          console.log("setSendSmsData.===>", error);
        });

      if (!(smsSendList.length > 0)) {
        this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
        return;
      }

      this.isReservationSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.reservationSmsSendPopup.showReservationSmsSendPopup(
          smsSendList,
          "R_RESVE",
          true,
          true
        );
      });
    },
    onChangeHistoryButtonClicked() {},
    async getRoomReservationDetail(rresveNo) {
      let data;
      try {
        data = await GolfErpAPI.fetchRoomCheckInDetail({
          rresveNo: rresveNo,
          bsnDate: this.searchOptions.bsnDate,
        });
      } catch (e) {
        console.error(e);
        await this.onRoomReservationDetailViewClosed(false);
        await this.viewButtonClicked();
        return;
      }

      this.roomReservationDetail.reservationInfo = data;
      this.roomReservationDetail.reservationInfo.golfPackageLink = (data?.trResveGroupMaps?.filter(groupMap => (groupMap?.tgResveConfirms?.length || 0) > 0).length > 0 ? "　√　" : null);
      this.roomReservationDetail.reservationDetails =
        data.trResveStays ?
          _orderBy(
            data.trResveStays.filter(item => this.searchOptions.isExcludResveCancel ? item.rresveStatus !== "CANCEL" : true).map(item => ({
              ...item,
              orgRoomNo: item.roomNo,
              roomAmt: item?.trResveStayPaymts?.map(item => item.roomAmt).reduce((acc, cur) => acc + cur, 0),
              isNewMemo: (item?.memoReceivedCount || 0) > (item?.memoConfirmCount || 0),
            })), ["arrivalDate", "departureDate", "guestName"]) :
          []
      ;
      this.roomReservationDetail.reservationDetailsCount = (data.trResveStays ? data.trResveStays : []).length;
      this.orgRoomReservationDetail.reservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));

      this.roomReservationDetail.checkoutList = data.checkoutList.map(item => ({
        ...item,
        payGiftAndTrans: item.payGift + item.payTrans,
        checkoutTime: item.chkoutDt ? moment(item.chkoutDt).format("HH:mm") : null,
      })) || [];
      this.roomReservationDetail.checkoutListCount = this.roomReservationDetail.checkoutList.length;

      let salesDate = null;
      this.roomReservationDetail.cashierList = data.cashierList.map(item => {
        const isView = salesDate !== item.salesDate;
        salesDate = item.salesDate;
        return ({
          ...item,
          isView: isView,
        });
      }) || [];
      this.roomReservationDetail.cashierListCount = this.roomReservationDetail.cashierList.length;

      salesDate = null;
      this.roomReservationDetail.salesList = data.salesList.map(item => {
        const isView = salesDate !== item.salesDate;
        salesDate = item.salesDate;
        return ({
          ...item,
          groupBySalesDate: item.salesDate,
          groupByStoreCode: item.storeCode,
          isView: isView,
          insertTime: moment(item.insertDt).format("HH:mm"),
        });
      }) || [];

      this.roomReservationDetail.creditList = data.creditList.map(item => ({
        ...item,
        approvalDivName: item.payDiv === 'CREDIT_DEF' ? item.approvalDiv : commonCodesGetComName("APPROVAL_DIV", item.approvalDiv),
        payStatus: item.isDeleted ? "취소" : "정상",
      }));

      this.isRoomResveDetailViewOpened = true;

      if (!(this.roomReservationDetail.reservationDetails.length > 0)) {
        this.onRoomReservationDetailViewClosed();
      }
    },
    isReservationDetailChanged() {
      // 수정중인 항목을 반영하기 위함(saveCell)
      this.$refs.roomResveDetailGrid.saveCell();

      const {
        addedRecords,
        changedRecords
      } = this.$refs.roomResveDetailGrid.getBatchChanges();

      const orgData = JSON.parse(JSON.stringify(this.orgRoomReservationDetail.reservationInfo));
      const modifyData = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));

      orgData.golfPackageLink = null;
      modifyData.golfPackageLink = null;

      return JSON.stringify(orgData) !== JSON.stringify(modifyData) || addedRecords.length > 0 || changedRecords.length > 0;
    },
    async onRoomReservationDetailViewClosed(isChangedConfirm = true) {
      if (isChangedConfirm) {
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }
      }

      this.roomReservationDetailInit();
      this.isRoomResveDetailViewOpened = false;
    },
    roomReservationDetailInit() {
      this.orgRoomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: null,
        groupName: null,
        golfPackageLink: null,
        grpNo: null,
        grpName: null,
        grpKind: null,
        resveName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: null,
        resveChannel: null,
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: null,
        smsSendFlag: null,
        smsSendCnt: null,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
      };
      this.roomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: null,
        groupName: null,
        golfPackageLink: null,
        grpNo: null,
        grpName: null,
        grpKind: null,
        resveName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: null,
        resveChannel: null,
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: null,
        smsSendFlag: null,
        smsSendCnt: null,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
      };
      this.roomReservationDetail.reservationDetails = [];
      this.roomReservationDetail.reservationDetailsCount = 0;

      // data 초기화후 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.groupName.setEmpty();
      this.$refs.grpName.setEmpty();
      this.$refs.resveName.setEmpty();
    },
    async onGrpNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        console.log(
          "## 단체명을 바꾼 후 Focus out. 단체 조회 후 결과에 따라 단체 팝업을 띄운다. 단체 검색어 :" +
          args.value
        );
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearGrpInfo();
          return;
        }
        await getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setGrpInfos(groupList[0]);
              this.reservationCheck(null, groupList[0].grpNo, null); // 위약 체크
            } else {
              this.onGroupPopupOpen(false, "TEMP");
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.grpNo = "";
        this.roomReservationDetail.reservationInfo.grpKind = "FIT";
        this.roomReservationDetail.reservationInfo.grpName = "";
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.grpNo = grpInfo.grpNo;
        this.roomReservationDetail.reservationInfo.grpKind = grpInfo.grpKind;
        this.roomReservationDetail.reservationInfo.grpName = grpInfo.grpName;
        this.roomReservationDetail.reservationInfo.resveChannel = grpInfo.resveChannel;
        if (grpInfo.grpKind !== "TEMP") {
          this.roomReservationDetail.reservationInfo.resveName =
            grpInfo.grpName;
          if (grpInfo.generName) {
            this.roomReservationDetail.reservationInfo.contactName =
              grpInfo.generName;
          } else {
            this.roomReservationDetail.reservationInfo.contactName = null;
          }
          if (grpInfo.generContactTel) {
            this.roomReservationDetail.reservationInfo.contactTel =
              grpInfo.generContactTel;
          } else {
            this.roomReservationDetail.reservationInfo.contactTel = null;
          }
          this.roomReservationDetail.reservationInfo.membershipId = null;
          this.roomReservationDetail.reservationInfo.memberNo = null;
          this.roomReservationDetail.reservationInfo.sexCode = "M";
          this.roomReservationDetail.reservationInfo.memberDiv = "NOM";
          this.roomReservationDetail.reservationInfo.memberGrade = commonCodesGetJsonData(
            "MEMBER_DIV",
            "NOM"
          ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        }
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.roomReservationDetail.reservationInfo
        .grpName
        ? (groupPopupData.grpName = this.roomReservationDetail.reservationInfo.grpName)
        : null;
      groupPopupData.resveDates =
        [
          _minBy(this.roomReservationDetail.reservationDetails.map(item => item.arrivalDate)) || this.searchOptions.bsnDate,
          _maxBy(this.roomReservationDetail.reservationDetails.map(item => item.departureDate)) || this.searchOptions.bsnDate
        ];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.roomReservationDetail.reservationInfo.grpName;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
          // this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
          // this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        } else {
          this.clearGrpInfo();
        }
      }
    },
    onGroupNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
          // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
          // #2. 회원/비회원 위임자의 경우
          // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명, 지역을 예약자명과 동일하게 설정
          // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
          //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 미 설정으로
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvNonMemberInfos();
            }
            return;
          }

          // if (!this.roomReservationDetail.reservationInfo.resveName) {
          //   this.setResvNonMemberInfos();
          // }

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.GROUP_NAME,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onResveNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
          // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
          // #2. 회원/비회원 위임자의 경우
          // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명, 지역을 예약자명과 동일하게 설정
          // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
          //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 미 설정으로
            this.setResvNonMemberInfos();
            return;
          }

          this.setResvNonMemberInfos();

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    async memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.roomReservationViewOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode ===
              this.roomReservationDetail.reservationInfo.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.roomReservationDetail.reservationInfo.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   this.reservationDetailInfo.reservationInfo.resveName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      } else if (data.popupData.popupType === this.memberPopupType.GROUP_NAME) {
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          if (!this.roomReservationDetail.reservationInfo.resveName) {
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (!data.selectedRowData.membershipId) {
            if (data.selectedRowData.memberName) {
              this.roomReservationDetail.reservationInfo.resveName = data.selectedRowData.memberName;
            }
            this.setResvNonMemberInfos();
          } else {
            this.setResvMemberInfos(data.selectedRowData);

            // // 위약 체크
            // this.reservationCheck(
            //   null,
            //   null,
            //   data.selectedRowData.membershipId
            // );
          }

          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   data.selectedRowData.memberName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }

          await this.$nextTick();
          this.$refs.resveName.focusIn();
        }
      } else if (data.popupData.popupType === this.memberPopupType.GROUP_NAME) {
        if (data.selectedRowData) {
          if (!data.selectedRowData.membershipId) {
            if (data.selectedRowData.memberName) {
              this.roomReservationDetail.reservationInfo.groupName = data.selectedRowData.memberName;
            }
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvNonMemberInfos();
            }
          } else {
            this.roomReservationDetail.reservationInfo.groupName = data.selectedRowData.memberName;
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvMemberInfos(data.selectedRowData);
            }

            // // 위약 체크
            // this.reservationCheck(
            //   null,
            //   null,
            //   data.selectedRowData.membershipId
            // );
          }

          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   data.selectedRowData.memberName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }

          await this.$nextTick();
          this.$refs.groupName.focusIn();
        }
      }
    },
    setResvNonMemberInfos() {
      // // 연계회원 clear
      // this.memberLinkList = [];

      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.resveName = this.roomReservationDetail.reservationInfo.resveName || this.roomReservationDetail.reservationInfo.groupName;
        this.roomReservationDetail.reservationInfo.membershipId = null;
        this.roomReservationDetail.reservationInfo.memberNo = null;
        this.roomReservationDetail.reservationInfo.sexCode = "M";
        this.roomReservationDetail.reservationInfo.memberDiv = "NOM";
        this.roomReservationDetail.reservationInfo.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.roomReservationDetail.reservationInfo.contactName = this.roomReservationDetail.reservationInfo.resveName;
        this.roomReservationDetail.reservationInfo.areaCode = commonCodeGetComCodeDefaultValue("AREA_CODE");
      }

      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();

      if (records.length > 0) {
        records.forEach((record, idx) => {
          if (!record.guestName) {
            this.$refs.roomResveDetailGrid.updateCell(idx, "guestName", this.roomReservationDetail.reservationInfo.resveName || this.roomReservationDetail.reservationInfo.groupName);
          }
        });
      }
    },
    setResvMemberInfos(memberInfo) {
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.resveName =
          memberInfo.memberName;
        this.roomReservationDetail.reservationInfo.membershipId =
          memberInfo.membershipId;
        this.roomReservationDetail.reservationInfo.memberNo =
          memberInfo.memberNo;
        this.roomReservationDetail.reservationInfo.memberDiv =
          memberInfo.memberDiv;
        this.roomReservationDetail.reservationInfo.memberGrade =
          memberInfo.memberGrade;
        this.roomReservationDetail.reservationInfo.sexCode = memberInfo.sexCode;
        if (this.commonMixinHasCiperTextGet) {
          // commonMixinHasCiperTextGet 권한이 있는 경우에만
          this.roomReservationDetail.reservationInfo.contactTel =
            memberInfo.hpNo;
        }
        this.roomReservationDetail.reservationInfo.contactName =
          memberInfo.memberName;
        this.roomReservationDetail.reservationInfo.areaCode =
          memberInfo.areaCode ? memberInfo.areaCode : commonCodeGetComCodeDefaultValue("AREA_CODE");
      }

      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();

      if (records.length > 0) {
        records.forEach((record, idx) => {
          if (!record.guestName) {
            this.$refs.roomResveDetailGrid.updateCell(idx, "guestName", memberInfo.memberName);
          }
        });
      }
    },
    onReservationInfoMemberDivDropdownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.roomReservationViewOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode ===
              this.roomReservationDetail.reservationInfo.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.roomReservationDetail.reservationInfo.membershipId = "";
                this.roomReservationDetail.reservationInfo.memberNo = "";
                this.roomReservationDetail.reservationInfo.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            this.roomReservationDetail.reservationInfo.resveName,
            null,
            null,
            true
          );
        }
      }
    },
    onContactTelInfoBlur() {
      const value = this.roomReservationDetail.reservationInfo.contactTel;
      if ((this.changeContactTel === null ? "" : this.changeContactTel) !== value) {
        // TODO : maskedTextBox의 위약 처리는 blur 이벤트에서 함(textbox와 달리, @change 이벤트에서 focus out 되는 상황을 잡아낼 수 없기 때문)
        // this.reservationCheck(value, null, null);
      }
      this.changeContactTel = value;
    },
    onResveKindChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        // this.reservationCheck(null, null, this.reservationDetailInfo.reservationInfo.membershipId, this.reservationDetailInfo.reservationInfo.resveNo);
      }
    },
    reservationInfoInit() {
      this.roomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: moment().format("YYYY-MM-DD"),
        groupName: null,
        golfPackageLink: null,
        grpNo: null,
        grpName: null,
        grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
        resveName: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: commonCodeGetComCodeDefaultValue("RESVE_KIND"),
        resveChannel: commonCodeGetComCodeDefaultValue("RESVE_CHANNEL"),
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: false,
        smsSendFlag: false,
        smsSendCnt: 0,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
      };
      this.orgRoomReservationDetail.reservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));
      this.roomReservationDetail.reservationDetails = [];
      this.$refs.roomResveDetailGrid.refresh();
    },
    onRoomDetailAddButtonClicked(event, args = {}) {
      const grid = this.$refs.roomResveDetailGrid;
      const reprsntRoomFlag = !(grid.getBatchCurrentViewRecords().length > 0);

      const selectedRecords = grid.getSelectedRecords();

      const addRecord = {
        reprsntRoomFlag: reprsntRoomFlag,
        frpyAbleFlag: reprsntRoomFlag,
        guestName: this.roomReservationDetail.reservationInfo.resveName,
        rresveStatus: "RESVE",
        arrivalDate: args?.arrivalDate || this.searchOptions.bsnDate || getTodayDate(),
        stayCnt: 1,
        departureDate: getDateOfNext(args?.arrivalDate || this.searchOptions.bsnDate || getTodayDate()),
        adultCnt: args?.roomType || selectedRecords[0]?.roomType ? commonCodesGetCommonCodeAttrbByCodeAndIdx("ROOM_TYPE", args?.roomType || selectedRecords[0]?.roomType, 1) || 0 : 0,
        childCnt: 0,
        roomSaleKind: "WALK", // commonCodeGetComCodeDefaultValue("ROOM_SALE_KIND"),
        roomDcKind: commonCodeGetComCodeDefaultValue("ROOM_DC_KIND"),
        roomType: args?.roomType || selectedRecords[0]?.roomType || null,
      };
      const _rid = grid.addRecord(addRecord);

      if (addRecord.roomType) {
        this.checkAvailableRooms(addRecord.roomType, addRecord.arrivalDate, addRecord.departureDate, null, grid, _rid);
      }
    },
    onRoomResveDetailGridDataBound() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        setTimeout(() => {
          this.$refs.groupName.focus();
        }, 500);
      }

      if (this.roomReservationDetail.reservationDetails.length > 0) {
        if (!(this.roomReservationDetail.reservationDetails.filter(item => item.reprsntRoomFlag).length > 0)) {
          this.$refs.roomResveDetailGrid.updateCell(0, "reprsntRoomFlag", true);
          this.$refs.roomResveDetailGrid.updateCell(0, "frpyAbleFlag", true);
        }
      }
    },
    onRoomResveDetailGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node: {
          classList
        },
      } = args;

      if (
        [
          "reprsntRoomFlag",
          "guestName",
          // "arrivalDate",
          // "stayCnt",
          // "departureDate",
          // "roomType",
          "roomNo",
        ].includes(field)
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        classList.add(this.$t("className.grid.requiredInputHeader"));
      } else if (
        [
          "roomAmt"
        ].includes(field)
      ) {
        classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "vacantRoomIcon") {
        classList.add(this.$t("className.grid.searchIconArea"));
      } else if (field === "memo") {
        classList.add(this.$t("className.grid.memoArea"));
      }
    },
    onRoomResveDetailGridQueryCellInfo(args) {
      const {
        column: {
          field,
          allowEditing,
        },
        cell: {
          classList,
          style,
        },
        data,
      } = args;

      args.cell.ariaDisabled = true;

      if (["roomType", "roomSaleKind"].includes(field)) {
        args.cell.setAttribute("column-name", field);
      }

      if (data.rresveStatus === "CANCEL") {
        style.textDecoration = "line-through";
      }

      if (allowEditing) {
        classList.add(this.$t("className.grid.modifyArea"));
      }

      if (
        [
          "roomAmt",
        ].includes(field)
      ) {
        classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "roomNo") {
        classList.add(this.$t("className.grid.clickAreaNone"));
      } else if (field === "vacantRoomIcon") {
        classList.add(this.$t("className.grid.searchIconArea"));
      } else if (field === "memo") {
        if (data.memoAllCount > 0) {
          if (data.isNewMemo && commonCodesGetStandardInfo("memoConfirmFlag")) {
            classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field === "orgRoomNo") {
        style.fontWeight = "bold";
      } else if (field === "rresveStatus") {
        // style.backgroundColor = commonCodesGetColorValue("RRESVE_STATUS", data["rresveStatus"]);
        style.color = commonCodesGetColorValue("RRESVE_STATUS", data["rresveStatus"]);
      }
    },
    onRoomResveDetailGridCellSaved(args) {
      const {
        column: {
          field,
        },
        rowData,
        value,
        previousValue,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;
      const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);

      if (["arrivalDate", "departureDate"].includes(field)) {
        if (!moment(value, "YYYY-MM-DD").isValid()) {
          this.errorToast("일자가 잘못되었습니다");
          grid.updateCell(rowIndex, field, previousValue);
          return;
        }

        const arrivalDate = (field === "arrivalDate" ? value : rowData["arrivalDate"]);
        const departureDate = (field === "departureDate" ? value : rowData["departureDate"]);

        if (arrivalDate > departureDate) {
          if (field === "departureDate") {
            this.errorToast("퇴실일자는 입실일자보다 이전일 수 없습니다");
            grid.updateCell(rowIndex, field, previousValue);
            return;
          } else {
            // 입실일자 변경시 박수로 퇴실일자를 재계산한다.
            const departureDateByStayCnt = moment(arrivalDate).add(rowData["stayCnt"], "days").format("YYYY-MM-DD");
            grid.updateCell(rowIndex, "departureDate", departureDateByStayCnt);
            if (value !== previousValue) {
              this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDateByStayCnt, rowData.stayId, grid);
            }
            return;
          }
        }
        const stayCnt = moment(departureDate).diff(moment(arrivalDate), "days");
        grid.updateCell(rowIndex, "stayCnt", stayCnt);

        if (value !== previousValue) {
          this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDate, rowData.stayId, grid);
        }
      } else if (field === "stayCnt") {
        const arrivalDate = rowData["arrivalDate"];
        const departureDate = moment(arrivalDate).add(value, "days").format("YYYY-MM-DD");
        grid.updateCell(rowIndex, "departureDate", departureDate);

        if (value !== previousValue) {
          this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDate, rowData.stayId, grid);
        }
      }
    },
    onRoomResveDetailGridCellSave(args) {
      const {
        column: {
          field,
        },
      } = args;

      if (["roomType", "roomSaleKind"].includes(field)) {
        this.setEjsDropdonwlistEditTemplateValue(args);
      }
    },
    setEjsDropdonwlistEditTemplateValue(args) {
      args.value = args.cell.firstElementChild.getElementsByClassName(
        'e-dropdownlist',
      )[0].ej2_instances[0].itemData?.comCode;
    },
    async onRoomResveDetailGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;
      // 수정중인 항목을 반영하기 위함(saveCell)
      grid.saveCell();

      if (field === "roomAmt") {
        if (this.isReservationDetailChanged()) {
          this.errorToast("수정된 데이터가 있습니다. 저장후 진행해주시기 바랍니다");
          return;
        }
        this.isRoomRateDetailsByDatePopup = true;
        this.$nextTick(() => {
          this.$refs.roomRateDetailsByDatePopup.showPopup({
            stayId: rowData["stayId"],
          });
        });
      } else if (field === "vacantRoomIcon") {
        // 체크인된 객실은 객실 변경 불가. 객실 변경은 EXT/TR 팝업에서 가능.
        if (rowData.roomId) {
          return;
        }
        const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);

        const currentViewRecords = grid.getBatchCurrentViewRecords();
        const records = currentViewRecords.filter(item => item._rid === rowData._rid);
        const {
          addedRecords,
          changedRecords,
        } = grid.patchBatchChanges();

        let roomIds = [];
        roomIds = roomIds.concat(
          addedRecords.filter(item => {
            const record = currentViewRecords.filter(record => record._rid === item._rid)[0] || {};
            return item.roomId &&
              record.arrivalDate <= records[0].arrivalDate &&
            (record.arrivalDate === record.departureDate ? true : record.departureDate >= records[0].arrivalDate);
          }).map(item => item.roomId)
        );
        roomIds = roomIds.concat(
          changedRecords.filter(item => {
            const record = currentViewRecords.filter(record => record._rid === item._rid)[0] || {};
            return item.roomId &&
              record.arrivalDate <= records[0].arrivalDate &&
            (record.arrivalDate === record.departureDate ? true : record.departureDate >= records[0].arrivalDate);
          }).map(item => item.roomId)
        );

        this.isVacantRoomPopup = true;
        this.$nextTick(() => {
          this.$refs.vacantRoomPopup.showPopup({
            arrivalDate: records[0].arrivalDate,
            departureDate: records[0].departureDate,
            roomType: records[0].roomType,
            stayId: rowData["stayId"],
            rowIndex: rowIndex,
            roomIds: roomIds,
          });
        });
      } else if (field === "roomNo") {
        const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);
        const {
          addedRecords,
        } = grid.patchBatchChanges();
        const addedRecord = addedRecords.find(item => item._rid === rowData["_rid"]);
        if (addedRecord) {
          if (addedRecord.roomId) {
            if (!(await this.confirm(`${addedRecord.roomNo}호 객실을 삭제하시겠습니까?`))) {
              return;
            }
            grid.updateCell(rowIndex, "roomId", null);
            grid.updateCell(rowIndex, "roomNo", null);
          }
        } else {
          if (rowData["roomId"]) {
            if (!(await this.confirm(`${rowData.roomNo}호 객실을 삭제하시겠습니까?`))) {
              return;
            }
            if (this.isReservationDetailChanged()) {
              if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
                return;
              }
            }
            await GolfErpAPI.checkInRoomCancel(rowData["stayId"]);

            await this.fetchRoomCheckIns();
            await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
          }
        }
      } else if (field === "memo") {
        await this.setStayId(rowData.stayId);
        this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
      }
    },
    async onRoomResveDetailGridRowSelected(args) {
      const { data } = args;

      if (data.stayId && data.stayId !== this.memoStayId) {
        await this.setStayId(data.stayId);
      } else if (!data.stayId) {
        await this.clearMemo();
      }
    },
    onRoomResveDetailGridActionComplete(args) {
      const {
        requestType,
        rows,
      } = args;

      if (requestType === "refresh") {
        if (rows?.length > 0) {
          const grid = this.$refs.roomResveDetailGrid;
          if (this.selectStayId) {
            // $nextTick : 실행되는 순서가 EjsGridWrapper.vue > onActionComplete 이벤트의 isAutoSelectCell 다음에 실행하게 하기 위함.
            this.$nextTick(() => {
              const rid = rows.filter(item => item?.data?.stayId === this.selectStayId)[0]?.data?._rid || null;

              if (rid) {
                const rowIndex = grid.getRowIndexByPrimaryKey(rid);
                grid.selectRow(rowIndex);
              } else {
                grid.selectRow(0);
              }

              this.selectStayId = null;
            });
          } else {
            this.$nextTick(() => {
              grid.selectRow(0);
            });
          }
        }
      }
    },
    async onGridCheckboxChanged(args) {
      const {
        columnName,
        rowData,
        rowIndex,
        value,
      } = args;

      if (columnName === "reprsntRoomFlag") {
        if (value) {
          if (rowData.rresveStatus === "CANCEL") {
            await this.$nextTick();
            this.$refs.roomResveDetailGrid.saveCell();
            this.$refs.roomResveDetailGrid.updateCell(rowIndex, columnName, false);
          } else {
            const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();

            records.forEach((record, idx) => {
              if (record._rid !== rowData._rid) {
                this.$refs.roomResveDetailGrid.updateCell(idx, columnName, false);
              }
            });
          }
        } else {
          await this.$nextTick();
          this.$refs.roomResveDetailGrid.saveCell();
          this.$refs.roomResveDetailGrid.updateCell(rowIndex, columnName, true);
        }
      }
    },
    async onGolfPackageLinkButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();
      const fromDate = _minBy(records.map(item => item.arrivalDate));
      const toDate = _maxBy(records.map(item => item.departureDate));
      this.isGolfPackageLinkPopup = true;
      this.$nextTick(() => {
        this.$refs.golfPackageLinkPopup.showPopup({
          fromDate,
          toDate,
          rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
        });
      });
    },
    onAvailableRoomPopupClosed() {
      this.isAvailableRoomPopup = false;
    },
    onAvailableRoomPopupConfirm(data) {
      if (this.isRoomResveDetailViewOpened) {
        this.onRoomDetailAddButtonClicked(null,{
          arrivalDate: data.bsnDate,
          roomType: data.roomType,
        });
      } else {
        this.onAddButtonClicked(null, {
          arrivalDate: data.bsnDate,
          roomType: data.roomType,
        });
      }
    },
    async onRoomRateDetailsByDatePopupClosed(args) {
      this.isRoomRateDetailsByDatePopup = false;

      if (args.isSaved) {
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    async onRoomReservationCancelPopupClosed(args) {
      this.isRoomReservationCancelPopup = false;

      await this.viewButtonClicked();
      await this.getRoomReservationDetail(args.rresveNo);
    },
    async onGolfPackageLinkPopupClosed(isSave, isDelete) {
      this.isGolfPackageLinkPopup = false;

      if (isSave || isDelete) {
        await this.fetchRoomCheckIns();
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    onVacantRoomPopupClosed(rowIndex, data) {
      this.isVacantRoomPopup = false;

      if (data) {
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomType", data.roomType);
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomNo", data.roomNo);
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomId", data.roomId);
      }
    },
    async onGolfPackageLinkClicked() {
      if (this.roomReservationDetail.reservationInfo.golfPackageLink) {
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        this.isUnlinkPackagePopup = true;

        this.$nextTick(() => {
          this.$refs.unlinkPackagePopup.showPopup({
            rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
          });
        });
      }
    },
    async onUnlinkPackagePopupClosed(isDelete) {
      this.isUnlinkPackagePopup = false;

      if (isDelete) {
        await this.fetchRoomCheckIns();
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    onReservationSmsSendPopupClosed() {
      this.isReservationSmsSendPopup = false;
    },
    async onTabSelected(args) {
      const {
        selectedIndex,
        previousIndex,
      } = args;

      let gridRefName;
      let preGridRefName;

      // 선택한 탭의 Grid 셋팅
      if (selectedIndex === 0) {
        gridRefName = "roomResveDetailGrid";
      } else if (selectedIndex === 1) {
        gridRefName = "roomCheckoutGrid";
      } else if (selectedIndex === 2) {
        gridRefName = "roomCashierGrid";
      } else if (selectedIndex === 3) {
        gridRefName = "roomSalesGrid";
      } else if (selectedIndex === 4) {
        gridRefName = "roomCreditGrid";
      } else {
        this.errorToast("탭 클릭시 오류가 발생하였습니다");
        this.$refs.tabByRoomDetail.select(previousIndex);
        return;
      }

      // 기존 탭의 Grid 셋팅
      if (previousIndex === 0) {
        preGridRefName = "roomResveDetailGrid";
      } else if (previousIndex === 1) {
        preGridRefName = "roomCheckoutGrid";
      } else if (previousIndex === 2) {
        preGridRefName = "roomCashierGrid";
      } else if (previousIndex === 3) {
        preGridRefName = "roomSalesGrid";
      } else if (previousIndex === 4) {
        preGridRefName = "roomCreditGrid";
      }

      const {
        addedRecords,
        changedRecords
      } = this.$refs[preGridRefName].getBatchChanges();

      // 기존 탭 Grid에 수정 사항이 있는지 확인.
      if (
        (addedRecords && addedRecords.length > 0) ||
        (changedRecords && changedRecords.length > 0)
      ) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          this.$refs.tabByRoomDetail.select(previousIndex);
          return;
        }
      }

      if (gridRefName) {
        // 수정된 데이터가 있어 기존 탭으로 Select한 경우엔 Grid Refresh 안하기 위한 IF문.
        if (this.selectTablDiv !== Object.values(TAB_BY_ROOM_DETAIL)[selectedIndex].DIV) {
          this.$refs[gridRefName].refresh();
          this.$refs[preGridRefName].refresh();
        }
      }

      this.selectTablDiv = Object.values(TAB_BY_ROOM_DETAIL)[selectedIndex].DIV;
    },
    memoConfirm(args) {
      const {
        stayId,
        newMemoFlag,
        memoViews,
      } = args;

      if (stayId) {
        const filterData = this.roomReservations.filter(item => item.stayId === stayId);

        if (filterData.length > 0) {
          if (!(filterData[0].memoAllCount > 0)) {
            filterData[0].memoAllCount = memoViews.length;
          }
          filterData[0].isNewMemo = newMemoFlag || false;
        }

        const filterDataByDetail = this.roomReservationDetail.reservationDetails.filter(item => item.stayId === stayId);

        if (filterDataByDetail.length > 0) {
          if (!(filterDataByDetail[0].memoAllCount > 0)) {
            filterDataByDetail[0].memoAllCount = memoViews.length;
          }
          filterDataByDetail[0].isNewMemo = newMemoFlag || false;
        }
      }
    },
    onRoomResveDetailGridClick(e) {
      if (e.srcElement) {
        const columnName = e.srcElement.getAttribute('column-name');
        if (columnName) {
          switch (columnName) {
            case "roomSaleKind":
            case "roomType":
              this.gridEjsDropdownlistEditTemplateEventBus.$emit(
                'click-open',
                columnName,
              );
              break;
          }
        }
      }
    },
    onVisitEjsDropdownListEditTemplateChanged(args) {
      const {
        columnName,
        rowData,
        value,
        rowIndex,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;

      if (columnName === "roomType") {
        this.checkAvailableRooms(value, rowData.arrivalDate, rowData.departureDate, rowData.stayId, grid, rowData._rid);

        const adultCnt = value ? commonCodesGetCommonCodeAttrbByCodeAndIdx("ROOM_TYPE", value, 1) || 0 : 0;
        grid.updateCell(rowIndex, "adultCnt", adultCnt);
      } else if (columnName === "roomSaleKind") {
        let roomAmt = this.roomReservationDetail.reservationDetails[rowIndex].roomAmt;
        if (value === "COMP") {
          roomAmt = 0;
        } else {
          roomAmt = this.roomReservationDetail.reservationDetails[rowIndex].roomAmt;
        }
        grid.updateCell(rowIndex,"roomAmt", roomAmt);
      }
    },
    async checkAvailableRooms(roomType, fromDate, toDate, stayId, grid, _rid) {
      if (!roomType) {
        return;
      }

      const { addedRecords } = grid ? grid.getBatchChanges() : { addedRecords: [] };

      if (_rid) {
        const findIndex = addedRecords.findIndex(item => item._rid === _rid);
        if (findIndex >= 0) {
          addedRecords.splice(findIndex, 1);
        }
      }

      await GolfErpAPI.checkAvailableRooms({
        fromDate: fromDate,
        toDate: toDate,
        roomType: roomType,
        addTrResveStays: addedRecords,
        stayId: stayId,
      });
    },
    async onRoomPostingPopupOpen() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      // 대표객실 Record
      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();
      const reprsntRecord = records.filter(item => item.reprsntRoomFlag);

      if (!(reprsntRecord.length > 0)) {
        this.errorToast("대표 객실이 없습니다");
        return;
      }

      if (!reprsntRecord[0].roomId) {
        this.errorToast("대표 객실 체크인 후 작업바랍니다");
        return;
      }

      const bsnDate = this.searchOptions.bsnDate;

      if (!(records.filter(item => item.arrivalDate <= bsnDate && item.departureDate >= bsnDate).length > 0)) {
        this.errorToast(`영업일자 ${bsnDate}에 투숙중인 대표객실이 없습니다. 다른 객실을 선택바랍니다`);
        return;
      }

      this.isRoomPostingPopup = true;

      this.$nextTick(async () => {
        this.$refs.roomPostingPopup.showPopup({
          roomId: reprsntRecord[0].roomId,
          guestName: reprsntRecord[0].guestName,
          bsnDate: bsnDate,
          stayId: reprsntRecord[0].stayId,
        });
      });
    },
    async onRoomPostingPopupClosed(args) {
      this.isRoomPostingPopup = false;
      if (args.isSaved) {
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    onRoomCheckoutGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          type,
        },
        data,
      } = args;

      if (type === "number") {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }

      if (field === "roomNo") {
        if (data.reprsntRoomFlag) {
          cell.style.fontWeight = "bold";
        }
      } else if (field === "checkoutTime") {
        if (data.rresveStatus === "CHECKOUT") {
          cell.style.backgroundColor = commonCodesGetColorValue("RRESVE_STATUS", data.rresveStatus);
          cell.style.color = "#ffffff";
        }
      }
    },
    onRoomCashierGridQueryCellInfo(args) {
      const {
        cell,
        data,
        column: {
          field,
        },
      } = args;

      if (field === "salesDate") {
        if (!data.isView) {
          cell.innerText = "";
          cell.style.borderTop = "none";
        }
      }

      if (data.isDeleted) {
        if (["totAmt", "payAmt"].includes(field)) {
          cell.style.textDecoration = "line-through";
        }
        cell.style.color = "#ff0000";
      }
    },
    onRoomSalesGridQueryCellInfo(args) {
      const {
        cell,
        data,
        column: {
          field,
        },
      } = args;

      if (field === "salesDate") {
        if (!data.isView && this.searchOptions.salesGroupDiv === "DATE") {
          cell.innerText = "";
          cell.style.borderTop = "none";
        }
      }

      if (data.isDeleted) {
        if (["totAmt","salePrice","saleQty","slipNo"].includes(field)) {
          cell.style.textDecoration = "line-through";
        }
        cell.style.color = "#ff0000";
      }
    },
    onRoomCreditGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node
      } = args;
      if (field === "dropdownMenu") {
        node.classList.add(this.$t("className.grid.contextMenuArea"));
      }
    },
    onRoomCreditGridQueryCellInfo(args) {
      const {
        cell,
        data,
        column: {
          field,
        },
      } = args;

      if (data.isDeleted) {
        if (["payAmt"].includes(field)) {
          cell.style.textDecoration = "line-through";
        }
        cell.style.color = "#ff0000";
      }
    },
    onPayButtonClicked() {
      this.isPayPopupOpen = true;

      const popupData = {
        bsnDate: this.searchOptions.bsnDate,
        storeCode: "CFRONT",
        salesDiv: "SALES",
        payDiv: "CREDIT_DEF",
        rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
        prpayFrpyDiv: "1",
      };

      this.$nextTick(() => {
        this.$refs.payPopup.showPopup(popupData);
      });
    },
    async payPopupClosed() {
      this.isPayPopupOpen = false;

      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    },
    onRoomCreditGridMenuSelected(args) {
      console.log('onRoomCreditGridMenuSelected.args.===>', args);
      const {
        data,
        menu,
      } = args;

      if (menu === "CANCEL") {
        if (data.isDeleted) {
          this.errorToast("이미 취소된 데이터입니다");
          return;
        }

        this.payCancel(data);
      } else if (menu === "REISSUE") {
        this.reissue(data);
      }
    },
    reissue(data) {
      if (
        data.payDiv === "DEPOSIT" ||
        data.payDiv === "PRECARD" ||
        (data.payDiv === "GIFT" && data.depositUseId)
      ) {
        this.isDepositUseReceiptPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.depositUseReceiptPopup.showDepositUseReceiptPopup(
            data.depositUseId
          );
        });
      } else {
        this.isReIssueReceiptPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
            salesDiv: "SALES",
            payId:
              data.payDiv === "CARD"
                ? data.cardId
                : data.payId,
            payDiv: data.payDiv,
            isPayCash: false,
            isCashCancel: data.payDiv === "CASH" && !data.delFlag && data.approvalDiv === "CANCEL" ? true : false
          });
        });
      }
    },
    async payCancel(data) {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      if (!(this.roomReservationDetail.reservationDetails.filter(item => item.stayId === data.stayId && item.rresveStatus === "CHECKIN").length > 0)) {
        this.errorToast("해당 객실이 체크아웃되어 정산 취소를 하실 수 없습니다");
        return;
      }

      const popupData = {
        salesDiv: "SALES",
        stayId: data.stayId,
        payId: data.payId,
        cardId: data.cardId,
        cashId: !data.delFlag && data.approvalDiv !== "CANCEL" ? data.cashId : null,
        useId: data.depositUseId,
        payDate: data.payDate,
        payDiv: data.payDiv,
        bsnDate: getTodayDate(),
        payAmt: data.payAmt,
        visitName: data.guestName,
        storeCode: data.storeCode,
      };

      this.isPayCancelPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.payCancelPopup.showPayCancelPopup(popupData);
      });
    },
    async payCancelPopupClosed(args) {
      this.isPayCancelPopupOpen = false;

      if (args.isReload) {
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    async onCheckoutButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const grid = this.$refs.roomCheckoutGrid;

      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("객실을 선택해 주시기 바랍니다");
        return;
      }

      const selectedRecord = selectedRecords[0];

      if (compareDate(this.searchOptions.bsnDate, selectedRecord.departureDate) < 0) {
        this.errorToast("해당 객실의 퇴실일자는 체크아웃 하실 수 없습니다");
        return;
      }

      await GolfErpAPI.roomCheckout(selectedRecord.stayId);

      await this.fetchRoomCheckIns();
      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    },
    async onAllCheckoutButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      if (!(await this.confirm("전체 객실을 체크아웃 하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.roomCheckoutByAll(this.roomReservationDetail.reservationInfo.rresveNo, this.searchOptions.bsnDate);

      await this.fetchRoomCheckIns();
      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    },
    async onTotalSumButtonClicked() {
      // 대표객실 Record
      const records = this.$refs.roomCheckoutGrid.getBatchCurrentViewRecords();
      const reprsntRecords = records.filter(item => item.reprsntRoomFlag);

      if (!(reprsntRecords.length > 0)) {
        this.errorToast("대표 객실이 없습니다");
        return;
      }

      const reprsntRecord = reprsntRecords[0];

      if (reprsntRecord.rresveStatus !== "CHECKIN") {
        this.errorToast("대표 객실이 체크인 상태가 아닙니다.");
        return;
      }

      if (!(await this.confirm(`${reprsntRecord.roomNo}호 객실(대표)로 전체합산을 진행하시겠습니까?`))) {
        return;
      }

      await GolfErpAPI.roomTotalSum(reprsntRecord.rresveNo, reprsntRecord.stayId);

      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    },
    async onRestorationButtonClicked() {
      if (!(await this.confirm("모든 객실의 전체합산 작업이 취소됩니다.<br>환원 하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.roomRestoration(this.roomReservationDetail.reservationInfo.rresveNo);

      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    },
    depositUseReceiptPopupClosed() {
      this.isDepositUseReceiptPopupOpen = false;
    },
    reIssueReceiptPopupClosed() {
      this.isReIssueReceiptPopupOpen = false;
    },
    async onExtTrButtonClicked() {
      const selectedRecords = this.$refs.roomResveDetailGrid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("객실을 선택해 주시기 바랍니다");
        return;
      }

      if (selectedRecords[0].rresveStatus !== "CHECKIN") {
        this.errorToast("체크인 상태가 아닙니다");
        return;
      }

      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isRoomExtTrPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.roomExtTrPopup.showPopup({
          stayId: selectedRecords[0].stayId,
          arrivalDate: selectedRecords[0].arrivalDate,
          roomNo: selectedRecords[0].roomNo,
        });
      });
    },
    async roomExtTrPopupClosed() {
      this.isRoomExtTrPopupOpen = false;

      await this.fetchRoomCheckIns();
      await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
    }
  },
};
</script>
