<template>
  <div>
    <ejs-dialog
        header="골프 패키지 연결을 해제 하시겠습니까?"
        ref="unlinkPackagePopup"
        width="400"
        v-bind="dialogProps"
        :allowDragging="true"
        :isModal="true"
        :showCloseIcon="true"
        :animationSettings="{ effect: 'None' }"
        :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="section-body" style="border: none;">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field" style="width: 100%;">
                            <!-- 필수 : required -->
                            <div class="title">삭제사유</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea
                                        class="lookup-condition-textarea"
                                        ref="delReason"
                                        v-model="delReason"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="delete keyColor">
              <erp-button
                button-div="DELETE"
                :is-shortcut-button="true"
                @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

import inputTextarea from "@/components/common/text/InputTextarea";
import ErpButton from "@/components/button/ErpButton.vue";

import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "UnlinkPackagePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components:{
    inputTextarea,
    ErpButton,
  },
  computed: {
    dialogProps() {
      return {
        position: { x: "center", y: "center" }
      };
    }
  },
  data(){
    return{
      rresveNo: null,
      resveNo: null,
      delReason: null,
      validateRefList: {
        delReason: {
          required: true
        }
      },
      isDelete: false,
    };
  },
  methods:{
    validateFormRefs,
    showPopup(args) {
      this.rresveNo = args.rresveNo;
      this.resveNo = args.resveNo;

      this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.delReason.focus();
          }, 100);
      });
    },
    async onDeleteButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      if (!(await this.confirm("객실 패키지 연결을 해지하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.deleteGolfPackageLink({
        rresveNo: this.rresveNo,
        resveNo: this.resveNo,
        delReason: this.delReason,
      });

      this.infoToast("골프 패키지 연결을 해제하였습니다");

      this.isDelete = true;

      this.onCloseButtonClicked();
    },
    onPopupClose() {
      this.$emit("popupClosed", this.isDelete);
    },
    onCloseButtonClicked() {
      this.$refs.unlinkPackagePopup.hide();
    },
  }
};

</script>

<style scoped>

</style>