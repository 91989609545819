<template>
  <div>
    <ejs-dialog
      ref="roomReservationCancelPopup"
      header="예약취소"
      width="1007"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window cancelReservation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약 취소정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field reservationName">
                              <!-- 필수 : required -->
                              <div class="title">예약자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                          v-model="roomReservationDetail.reservationInfo.resveName"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                              type="checkbox"
                                              id="resveSmsSendFlag"
                                              value="true"
                                              v-model="isSendSms"
                                          />
                                          <i></i>
                                          <div class="label">SMS</div>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">연락처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                          :max-length="11"
                                          v-model="roomReservationDetail.reservationInfo.contactTel"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">객실 취소 정보</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        :dataSource="roomReservationDetail.reservationDetails"
                        v-bind="gridProps"
                      />
                    </div>
                  </div>
                </section>
                <section class="article-section section-03">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">취소사유</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field reservationName">
                              <div class="title" style="display: none">
                                취소사유
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          id="cancelReason"
                                          ref="cancelReason"
                                          v-model="cancelReason"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="cancel">
              <erp-button
                button-div="DELETE"
                :is-shortcut-button="true"
                @click.native="onCancelReservationButtonClicked"
              >
                예약취소
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import ComponentTelephone from "@/components/ComponentTelephone";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

import { validateFormRefs, } from "@/utils/formUtil";
import {commonCodesGetCommonCodeAttrbNameByCodeAndIdx, commonCodesGetStandardInfo} from "@/utils/commonCodes";

export default {
  name: 'RoomReservationCancelPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputText,
    InputTextarea,
    ComponentTelephone,
    ErpButton,
  },
  data() {
    return {
      roomReservationDetail: {
        reservationInfo: {
          rresveNo: null,
          resveDate: null,
          groupName: null,
          golfLink: null,
          grpNo: null,
          grpName: null,
          grpKind: null,
          resveName: null,
          membershipId: null,
          memberNo: null,
          memberDiv: null,
          memberGrade: null,
          contactName: null,
          contactTel: null,
          resveKind: null,
          resveChannel: null,
          areaCode: null,
          sexCode: null,
          resveRemarks: null,
          frontMemo: null,
          vipFlag: null,
          smsSendFlag: null,
          smsSendCnt: null,
          smsSendDt: null,
          insertName: null,
          insertDt: null,
        },
        reservationDetails: [],
        reservationDetailsCount: 0,
      },
      isSendSms: true,
      cancelReason: null,
      popupData: null,
      formValidationRules: {
        cancelReason: {
          required: true,
        },
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        columns: [
          {
            field: "guestName",
            headerText: "투숙객",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "Center",
            isDateType: true,
          },
          {
            field: "stayCnt",
            headerText: "박수",
            allowEditing: false,
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 50,
            textAlign: "Center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "Center",
            isDateType: true,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "ROOM_SALE_KIND",
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            allowEditing: false,
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
        ],
      };
    },
  },
  methods: {
    validateFormRefs,
    async showPopup(popupData) {
      this.popupData = JSON.parse(JSON.stringify(popupData));

      await this.getRoomReservationDetail();

      if (!(this.roomReservationDetail.reservationDetails.length > 0)) {
        this.errorToast("취소 가능한 객실이 존재하지 않습니다");
        this.onCloseButtonClicked();
        return;
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.cancelReason.focus();
        }, 100);
      });
    },
    async getRoomReservationDetail() {
      const data = await GolfErpAPI.fetchRoomReservationDetail({
        rresveNo: this.popupData.rresveNo,
        stayIds: this.popupData.stayIds,
      });

      this.roomReservationDetail.reservationInfo = data;
      this.roomReservationDetail.reservationDetails = data.trResveStays ? data.trResveStays.filter(item => item.rresveStatus !== "CANCEL") : [];
    },
    async onCancelReservationButtonClicked() {
      if (!(await this.confirm("예약을 취소하시겠습니까?"))) {
        return;
      }

      if (!this.validateFormRefs(this.formValidationRules)) {
        return;
      }

      this.roomReservationDetailCancel();
    },
    async roomReservationDetailCancel() {
      try {
        await GolfErpAPI.roomReservationDetailCancel({
          stayIds: this.roomReservationDetail.reservationDetails.map(item => item.stayId),
          cancelReason: this.cancelReason,
        });

        this.infoToast("예약 취소되었습니다");

        if (this.isSendSms) {
          await this.autoSendSms();
        }

        this.onCloseButtonClicked();
      } catch (e) {
        console.error(e);
        // this.errorToast("예약 취소 또는 SMS 발송 중 오류가 발생하였습니다");
      }
    },
    async autoSendSms() {
      let smsSendList = [];
      let sendData = {};

      sendData.sendKey = this.roomReservationDetail.reservationInfo.rresveNo;
      sendData.name =
        this.roomReservationDetail.reservationInfo.resveName +
        " " +
        commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
          "MEMBER_DIV",
          this.roomReservationDetail.reservationInfo.memberDiv,
          1
        )
      ;
      sendData.resveNo = this.roomReservationDetail.reservationInfo.rresveNo;

      const smsRecptnDiv = this.roomReservationDetail.reservationInfo?.tgMembership?.memberInfo?.smsRecptnDiv || "Y";

      if (smsRecptnDiv === "A") {
        if (this.roomReservationDetail.reservationInfo.contactTel) {
          sendData.receiverName = this.roomReservationDetail.reservationInfo.resveName;
          sendData.contactTel = this.roomReservationDetail.reservationInfo.contactTel;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }

        if (this.roomReservationDetail.reservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
          sendData.receiverName = this.roomReservationDetail.reservationInfo.resveName;
          sendData.contactTel = this.roomReservationDetail.reservationInfo?.tgMembership?.memberInfo?.resveMngrHp;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else if (smsRecptnDiv === "B") {
        if (this.roomReservationDetail.reservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
          sendData.receiverName = this.roomReservationDetail.reservationInfo.resveName;
          sendData.contactTel = this.roomReservationDetail.reservationInfo?.tgMembership?.memberInfo?.resveMngrHp;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      } else {
        if (this.roomReservationDetail.reservationInfo.contactTel) {
          sendData.receiverName = this.roomReservationDetail.reservationInfo.resveName;
          sendData.contactTel = this.roomReservationDetail.reservationInfo.contactTel;

          smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      }

      // SMS 전송할 데이터 없으면 RETURN.
      if (!(smsSendList.length > 0)) {
        return;
      }

      const smsSendInfo = {
        type: "R_CANCEL",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        let smsInfo = {
          type: smsSendInfo.type,
          kakaoFlag: smsSendInfo.kakaoSendFlag,
          smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
          kakaoDispatchKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoDispatchKey
            : null,
          kakaoTemplateKey: smsSendInfo.kakaoSendFlag
            ? smsSendInfo.kakaoTemplateCode
            : null,
          key: data.sendKey,
          reservedFlag: false,
          reserveDateTime: null,
          receiverName: data.receiverName,
          receiverNumber: data.contactTel,
          subject: smsSendInfo.subject,
          message: message,
          replaceDataMap: {
            name: data.name,
            resveNo: data.resveNo,
            headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : "") : ""
          },
          dispatchNumber: smsSendType.dsptchNo,
          sender: "ERP",
        };

        smsRequestList.push(smsInfo);
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    onCloseButtonClicked() {
      this.$refs.roomReservationCancelPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", {
        rresveNo: this.popupData.rresveNo,
      });
    },
  },
};
</script>
