<template>
  <div>
    <ejs-dialog
      ref="roomRateDetailsByDatePopup"
      header="일자별 객실료 상세"
      width="1007"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약번호</div>
                    <ul class="content">
                      <li class="item text">
                        {{ title.rresveNo }}
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">투숙ID</div>
                    <ul class="content">
                      <li class="item text">
                        {{ title.stayId }}
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">투숙객</div>
                    <ul class="content">
                      <li class="item text">
                        {{ title.guestName }}
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">객실타입/번호</div>
                    <ul class="content">
                      <li class="item text">
                        {{ `${title.roomType ? commonCodesGetComName("ROOM_TYPE", title.roomType) : ""} / ${title.roomNo}` }}
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">기간</div>
                    <ul class="content">
                      <li class="item text">
                        {{ `${title.arrivalDate} ~ ${title.departureDate} (${title.stayCnt})` }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">가용 객실</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="roomRateDetails"
                        @queryCellInfo="onGridQueryCellInfo"
                        @cellSaved="onGridCellSaved"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="onSaveButtonClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'RoomRateDetailsByDatePopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      isSaved: false,
      searchOptions: {
        stayId: null,
      },
      title: {
        rresveNo: "",
        stayId: "",
        guestName: "",
        roomType: "",
        roomNo: "",
        arrivalDate: "",
        departureDate: "",
        stayCnt: 0,
      },
      roomRateDetails: [],
    };
  },
  created() {},
  mounted() {},
  computed: {
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        columns: [
          {
            field: "roomAmtId",
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "isClose",
            headerText: "마감",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 50,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "salesOccurFlag",
            headerText: "매출발생",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 70,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "salesDate",
            headerText: "투숙일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "dwCode",
            headerText: "요일",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 50,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DW_CODE",
            allowEditing: false,
          },
          {
            field: "roomBsnCode",
            headerText: "영업구분",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_BSN_CODE",
            allowEditing: false,
          },
          {
            field: "salePrice",
            headerText: "객실단가",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 80,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "priceDiv",
            headerText: "단가구분",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: commonCodesGetCommonCode("PRICE_DIV", true).filter(item => ["NOR", "RATE", "AMT"].includes(item.comCode)),
          },
          {
            field: "dcAmtRate",
            headerText: "할인(%,금액)",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 8,
            },
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 8,
            },
            minWidth: 16,
            width: 80,
            textAlign: "right",
          },
          {
            field: "isNotax",
            headerText: "면세",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "dcContents",
            headerText: "할인내용",
            type: "string",
            minWidth: 16,
            width: 180,
          },
        ],
      };
    },
  },
  methods: {
    commonCodesGetComName,
    showPopup(args) {
      this.searchOptions.stayId = args.stayId;

      this.getRoomResveStayPaymtDetail();
    },
    async getRoomResveStayPaymtDetail() {
      const data = await GolfErpAPI.fetchRoomResveStayPaymtDetail(this.searchOptions.stayId);

      this.title = {
        rresveNo: data.rresveNo,
        stayId: data.stayId,
        guestName: data.guestName,
        roomType: data.roomType,
        roomNo: data.roomNo ? data.roomNo : "",
        arrivalDate: data.arrivalDate,
        departureDate: data.departureDate,
        stayCnt: data.stayCnt,
      };

      this.roomRateDetails =
        data.roomSaleKind === "COMP" ?
          [] :
          _orderBy(data?.trResveStayPaymts?.map(item => ({
            ...item,
            isNotax: item.notaxCode === "NOTAX",
            dcAmtRate: item.priceDiv === "RATE" ? item.dcRate : item.dcAmt,
            dwCode: item?.trCalender?.dwCode,
            roomBsnCode: item?.trCalender?.roomBsnCode,
          })), ["salesDate"]);
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          groupCode,
        },
        data,
      } = args;

      if (["dwCode", "roomBsnCode"].includes(field)) {
        cell.style.color = commonCodesGetColorValue(groupCode, data[field]);
      } else if (field === "isNotax") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onGridCellSaved(args) {
      const {
        column: {
          field,
        },
        rowData,
        previousValue,
        value,
      } = args;

      const grid = this.$refs.grid;
      const rowIndex = grid.getRowIndexByPrimaryKey(rowData["roomAmtId"]);

      if (rowData["isClose"]) {
        if (value !== previousValue) {
          this.errorToast("일마감되어 수정이 불가합니다");
          grid.updateCell(rowIndex, field, previousValue);
        }
      } else if (field === "roomAmt") {
        if (value !== previousValue) {
          const salePrice = rowData["salePrice"];

          grid.updateCell(rowIndex, "dcAmtRate", salePrice - value);
          grid.updateCell(rowIndex, "priceDiv", "AMT");
        }
      } else if (["dcAmtRate", "priceDiv"].includes(field)) {
        const salePrice = rowData["salePrice"];
        const priceDiv = field === "priceDiv" ? value : rowData["priceDiv"];
        const dcAmtRate = field === "dcAmtRate" ? value : rowData["dcAmtRate"];

        if (dcAmtRate !== 0) {
          let roomAmt = 0;

          if (field === "priceDiv" && priceDiv === "NOR") {
            roomAmt = rowData["salePrice"];
            grid.updateCell(rowIndex, "dcAmtRate", 0);
          } else if (["NOR", "RATE"].includes(priceDiv)) {
            roomAmt = salePrice - Math.round(salePrice * (dcAmtRate / 100));
            if (priceDiv === "NOR") {
              grid.updateCell(rowIndex, "priceDiv", "RATE");
            }
          } else if (priceDiv === "AMT") {
            roomAmt = salePrice - dcAmtRate;
          }

          if (roomAmt < 0) {
            roomAmt = 0;
          }

          grid.updateCell(rowIndex, "roomAmt", roomAmt);
        } else {
          grid.updateCell(rowIndex, "priceDiv", "NOR");
          grid.updateCell(rowIndex, "roomAmt", rowData["salePrice"]);
        }
      }
    },
    async onSaveButtonClick() {
      const grid = this.$refs.grid;
      const {
        changedRecords,
      } = grid.patchBatchChanges();

      if (!(changedRecords.length > 0)) {
        this.errorToast("수정된 내용이 없습니다");
        return;
      }

      const currentViewRecords = grid.getBatchCurrentViewRecords();

      await GolfErpAPI.roomResveStayPaymtsSave(
        changedRecords.map(item => ({
          ...item,
          stayId: this.searchOptions.stayId,
          dcAmt: item.dcAmtRate,
          notaxCode: item.isNotax ? "NOTAX" : (item.isNotax !== null && item.isNotax !== undefined ? "TAX" : null),
          priceDiv: item.priceDiv ? item.priceDiv : (currentViewRecords.filter(record => record._rid === item._rid)[0]?.priceDiv || null),
          roomAmt: item.roomAmt ? item.roomAmt : (currentViewRecords.filter(record => record._rid === item._rid)[0]?.roomAmt),
        }))
      );

      this.infoToast("저장되었습니다");

      this.isSaved = true;

      this.getRoomResveStayPaymtDetail();
    },
    onCloseButtonClicked() {
      this.$refs.roomRateDetailsByDatePopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", {
        isSaved: this.isSaved
      });
    },
  },
};
</script>
