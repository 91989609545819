<template>
  <div>
    <ejs-dialog
      ref="RoomPostingPopup"
      :header="`객실 POSTING [${searchOptions.bsnDate}]`"
      width="800"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field roomNo">
                    <div class="title">객실번호</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          :readonly="true"
                          v-model="searchOptions.roomId"
                        />
                      </li>
                    </ul>
                    <ul class="content">
                      <li class="item button">
                        <ul class="button">
                          <li class="search">
                            <erp-button
                              button-div="GET"
                              @click.native="onCheckInRoomSearchButtonClicked"
                            >
                              검색
                            </erp-button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">투숙객명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          :readonly="true"
                          v-model="searchOptions.guestName"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article article-01">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">상품 목록</div>
                      </div>
                      <div class="header-right">
                        <ul class="lookup-condition">
                          <div class="title">매장</div>
                          <li class="field">
                            <ul class="content">
                              <li class="item">
                                <ejs-dropdownlist
                                  v-model="searchOptions.storeCode"
                                  :dataSource="searchOptions.storeCodeOptions"
                                  :fields="commonCodeFields"
                                  :allowFiltering="false"
                                  cssClass="body-data-dropdown"
                                  @change="onStoreCodeChange"
                                />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                        ref="productGrid"
                        v-bind="productGridProps"
                        :dataSource="products"
                        @rowSelected="onProductGridRowSelected"
                      />
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">매출 상세</div>
                        <div class="header-caption">{{ checkIn.guestName + "(" + checkIn.roomId + ")" }}</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="reset">
                            <erp-button
                              button-div="GET"
                              @click.native="onSalesDetailInitButtonClick"
                            >
                              초기화
                            </erp-button>
                          </li>
                          <li class="save">
                            <erp-button
                              button-div="SAVE"
                              @click.native="onSaveButtonClicked"
                            >
                              저장
                            </erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field productName">
                                <div class="title required">상품명</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ salesDetail.productName }}
                                    </li>
                                    <li class="item form-group text productCode">
                                      {{ salesDetail.productCode }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title required">단가</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group input">
                                      <div class="form">
                                        <input-number
                                          ref="price"
                                          :propMaxLength="8"
                                          v-model="salesDetail.price"
                                          @change="calculateTaxAmount"
                                        />
                                      </div>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title required">수량</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group input">
                                      <div class="form">
                                        <input-number
                                          ref="qty"
                                          :propMaxLength="4"
                                          v-model="salesDetail.qty"
                                          @change="calculateTaxAmount"
                                        />
                                      </div>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">적용사항</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="salesDetail.isSvc"
                                              @change="calculateTaxAmount"
                                            />
                                            <i></i>
                                            <div class="label">봉사료</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="salesDetail.isVat"
                                              @change="calculateTaxAmount"
                                            />
                                            <i></i>
                                            <div class="label">과세</div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title">공급가액</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ numberWithCommas(salesDetail.netAmt) }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title">봉사료</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ numberWithCommas(salesDetail.svcAmt) }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title">부가세</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ numberWithCommas(salesDetail.vatAmt) }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field two">
                                <div class="title">총금액</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ numberWithCommas(salesDetail.totAmt) }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field">
                                <div class="title">비고</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group input">
                                      <div class="form">
                                        <input-textarea
                                          :resize="false"
                                          v-model="salesDetail.remarks"
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
              <article class="body-article article-02">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">POSTING 전표 목록</div>
                      <div class="header-caption">[{{ numberWithCommas(postingSlipsCount) }}건]</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="postingSlipGrid"
                      v-bind="postingSlipGridPros"
                      :dataSource="postingSlips"
                      @actionComplete="onPostingSlipGridActionComplete"
                      @queryCellInfo="onPostingSlipGridQueryCellInfo"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <check-in-room-search-popup
      v-if="isCheckInRoomSearchPopup"
      ref="checkInRoomSearchPopup"
      @popupClosed="onCheckInRoomSearchPopupClosed"
      @popupConfirm="onCheckInRoomSearchPopupConfirm"
    />
    <delete-confirm-popup
      v-if="isDeleteConfirmPopup"
      ref="deleteConfirmPopup"
      :propsDeleteButtonClicked="postingSlipDelete"
      :propsBatchDeleteButtonClicked="onBatchDeleteConfirmPopupDeleteButtonClicked"
      @popupClosed="onDeleteConfirmPopupClosed"
    />
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
body .window .windowContent .body-article.article-01 {height: 228px;}
body .window .windowContent .body-article.article-02 {height: calc(100% - 228px);}

body .window .windowContent .body-article.article-01 .article-left {width: 45%;}
body .window .windowContent .body-article.article-01 .article-right {width: 55%;}
body .window .windowContent .body-article.article-01 .article-left .section-header .header-right .lookup-condition .field {width: 150px;}

body .window .windowContent .content-lookup .lookup-left .lookup-condition .field.roomNo .content .item {width: 90px;}
body .window .windowContent .content-lookup .lookup-left .lookup-condition .field.roomNo .content .item.button {width: 23px;}

body .body-data .data-content .field {width: 100%;}
body .body-data .data-content .field .title {width: 100px;}
body .body-data .data-content .field .content {width: calc(100% - 100px + 1px); border-right: none;}
body .body-data .data-content .field.two {width: calc(100% / 2 + 1px);}
body .body-data .data-content .field .content .row .item.form-group.text {width: 100%; text-align: right;}
body .body-data .data-content .field.productName .content .row .item.form-group.text {width: 205px;  text-align: left;}
body .body-data .data-content .field.productName .content .row .item.form-group.text.productCode {width: 105px;}

body .window .windowContent .body-article.article-02 .article-section .section-body {height: calc(100% - 46px);}
body .window .windowContent .body-article.article-02 .article-section .section-body >>> .e-grid.e-lib .dropdownbutton {margin: 0 -8px;}
body .window .windowContent .body-article.article-02 .article-section .section-body >>> .e-grid.e-lib .dropdownbutton .e-btn {
  overflow: hidden;
  box-shadow: none;
  width: 23px;
  height: 23px;
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent url(../../../assets/images/common/button-icon-etc.png) no-repeat center center;
  text-indent: -1000px;
}
</style>

<script>
import Vue from "vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {
  commonCodesGetCommonCodeByIdxAttrb,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {numberWithCommas} from "@/utils/number";
import {validateFormRefs} from "@/utils/formUtil";
import {calculateTaxAmount} from "@/utils/calculate";
import CheckInRoomSearchPopup from "@/views/room-front/popup/CheckInRoomSearchPopup";
import DeleteConfirmPopup from "@/components/popups/DeleteConfirmPopup";
import { orderBy as _orderBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'RoomPostingPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputText,
    InputTextarea,
    InputNumber,
    CheckInRoomSearchPopup,
    DeleteConfirmPopup,
    ErpButton,
  },
  data() {
    return {
      isSaved: false,
      isSaveButtonClicked: false,
      isCheckInRoomSearchPopup: false,
      isDeleteConfirmPopup: false,
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        roomId: null,
        guestName: null,
        bsnDate: null,
        stayId: null,
        storeCode: null,
        storeCodeOptions: commonCodesGetCommonCodeByIdxAttrb("STORE_CODE", 2, "ROOM"),
      },
      checkIn: {
        roomId: null,
        guestName: null,
        stayId: null,
        rresveNo: null,
      },
      salesDetail: {
        productCode: null,
        productName: null,
        price: 0,
        qty: 1,
        isVat: true,
        isSvc: false,
        netAmt: 0,
        svcAmt: 0,
        vatAmt: 0,
        totAmt: 0,
        remarks: null,
        storeCode: null,
      },
      products: [],
      postingSlips: [],
      postingSlipsCount: 0,
      validateRefList: {
        price: {
          required: true,
          min: 1,
        },
        qty: {
          required: true,
          min: 1,
        },
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    productGrid() {
      return this.$refs.productGrid;
    },
    postingSlipGrid() {
      return this.$refs.postingSlipGrid;
    },
    productGridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        isAutoSelectRow: false,
        isSelectedRowRetain: false,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "code",
            headerText: "상품코드",
            type: "string",
            minWidth: 16,
            width: 90,
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "name",
            headerText: "상품명",
            type: "string",
            minWidth: 16,
            width: 170,
          },
          {
            field: "price",
            headerText: "단가",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
        ],
      };
    },
    postingSlipGridPros() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        noColumnWidth: 35,
        columns: [
          {
            field: "slipId",
            type: "string",
            minWidth: 16,
            width: 90,
            visible: false,
            isPrimaryKey: true,
            allowEditing: false,
          },
          {
            field: "salesDate",
            headerText: "매출일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "Center",
            allowEditing: false,
          },
          {
            field: "roomNo",
            headerText: "객실",
            type: "string",
            minWidth: 16,
            width: 70,
            allowEditing: false,
          },
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "STORE_CODE",
            allowEditing: false,
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "productName",
            headerText: "상품명",
            type: "string",
            minWidth: 16,
            width: 120,
            allowEditing: false,
          },
          {
            field: "saleQty",
            headerText: "수량",
            type: "number",
            minWidth: 16,
            width: 60,
            textAlign: 'right',
            isNumericType: true,
            allowEditing: false,
          },
          {
            field: "totAmt",
            headerText: "금액",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: 'right',
            isNumericType: true,
            allowEditing: false,
          },
          {
            field: "dropdownMenu",
            headerText: "",
            width: 24,
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            template: () => {
              return {
                template: {
                  extends: Vue.component("payInfoGridDropdownTemplate", {
                    template:
                      "<div class='dropdownbutton' style=''><ejs-dropdownbutton :items='meunItems' :select='onMenuSelect' :disabled='data.isDeleted'></ejs-dropdownbutton></div>",
                    props: ["payInfoGridMenuSelectedCallback"],
                    data() {
                      return {
                        meunItems: [],
                        data: {
                          data: {},
                        },
                      };
                    },
                    mounted() {
                      this.meunItems =
                        [
                          {
                            text: "전표삭제",
                            value: "DELETE",
                          },
                        ];
                    },
                    methods: {
                      onMenuSelect: function (args) {
                        let eventParam = {
                          menu: args.item.value,
                          data: this.data,
                        };
                        this.payInfoGridMenuSelectedCallback(eventParam);
                      },
                    },
                  }),
                  propsData: {
                    payInfoGridMenuSelectedCallback: this
                      .onPostingSlipGridMenuSelected,
                  },
                },
              };
            },
          },
          {
            field: "isPay",
            headerText: "정산",
            type: "boolean",
            minWidth: 16,
            width: 60,
            textAlign: "Center",
            displayAsCheckBox: true,
            allowEditing: false,
          },
          {
            field: "isClose",
            headerText: "마감",
            type: "string",
            minWidth: 16,
            width: 60,
            allowEditing: false,
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 300,
            allowEditing: false,
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "Center",
            allowEditing: false,
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    showPopup(args) {
      this.searchOptions.roomId = args.roomId;
      this.searchOptions.guestName = args.guestName;
      this.searchOptions.bsnDate = args.bsnDate;
      this.searchOptions.stayId = args.stayId;

      this.fetchRoomCheckInPosting();
    },
    async fetchRoomCheckInPosting() {
      const data = await GolfErpAPI.fetchRoomCheckInPosting(this.searchOptions.stayId);

      this.checkIn.roomId = data.checkIn.roomId;
      this.checkIn.guestName = data.checkIn.guestName;
      this.checkIn.stayId = data.checkIn.stayId;
      this.checkIn.rresveNo = data.checkIn.rresveNo;
      this.postingSlips = _orderBy(data?.slips, ["salesDate", "slipId"], ["desc", "desc"]) || [];
      this.postingSlipsCount = this.postingSlips.length;
    },
    onViewButtonClicked() {
      this.fetchRoomCheckInPosting();
    },
    onCloseButtonClicked() {
      this.$refs.RoomPostingPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", { isSaved: this.isSaved });
    },
    onStoreCodeChange() {
      this.getStoreProducts();
    },
    async getStoreProducts() {
      const data = await GolfErpAPI.fetchStoreProducts(this.searchOptions.storeCode, null, this.searchOptions.bsnDate);

      this.products = data;
    },
    onProductGridRowSelected(args) {
      const {
        data,
      } = args;

      const taxData = calculateTaxAmount(data.price, data.noTaxCode);

      this.salesDetail = {
        productCode: data.code,
        productName: data.name,
        price: data.price,
        qty: 1,
        isVat: data.noTaxCode === "TAX",
        isSvc: false,
        netAmt: taxData?.netAmt || 0,
        svcAmt: taxData?.svcAmt || 0,
        vatAmt: taxData?.vatAmt || 0,
        totAmt: data.price,
        remarks: null,
        storeCode: data.storeCode,
      };
    },
    onSalesDetailInitButtonClick() {
      this.isSaveButtonClicked = false;

      this.productGrid.clearSelection();

      this.salesDetail = {
        productCode: null,
        productName: null,
        price: 0,
        qty: 1,
        isVat: true,
        isSvc: false,
        netAmt: 0,
        svcAmt: 0,
        vatAmt: 0,
        totAmt: 0,
        remarks: null,
      };
    },
    calculateTaxAmount() {
      const totAmt = this.salesDetail.price * this.salesDetail.qty;

      const data = calculateTaxAmount(totAmt, this.salesDetail.isVat ? "TAX" : "NOTAX", this.salesDetail.isSvc);

      this.salesDetail.netAmt = data.netAmt || 0;
      this.salesDetail.svcAmt = data.svcAmt || 0;
      this.salesDetail.vatAmt = data.vatAmt || 0;
      this.salesDetail.totAmt = totAmt;
    },
    async onSaveButtonClicked() {
      this.isSaveButtonClicked = true;
      // Validation 체크
      if (!this.salesDetail.productCode) {
        this.errorToast("상품을 선택해 주시기 바랍니다");
        this.isSaveButtonClicked = false;
        return;
      }
      if (!this.validateFormRefs(this.validateRefList)) {
        this.isSaveButtonClicked = false;
        return;
      }
      if (!this.checkIn.roomId || !this.checkIn.stayId) {
        this.errorToast("객실을 선택해 주시기 바랍니다");
        this.isSaveButtonClicked = false;
        return;
      }

      try {
        await GolfErpAPI.roomPostingSave(({
          ...this.salesDetail,
          bsnDate: this.searchOptions.bsnDate,
          stayId: this.checkIn.stayId,
        }));

        this.infoToast("저장되었습니다");
      } catch (e) {
        console.error(e);
        this.isSaveButtonClicked = false;
      }

      this.isSaved = true;

      this.fetchRoomCheckInPosting();
    },
    onPostingSlipGridActionComplete(args) {
      const {
        requestType,
        action
      } = args;

      if (requestType === "filtering") {
        if (action === "clearFilter") {
          this.postingSlipsCount = this.postingSlips.length;
        } else if ((args?.rows?.length || 0) > 0) {
          const filteredRecords = this.$refs.roomResveGrid.getFilteredRecords();
          this.postingSlipsCount = filteredRecords.length || 0;
        } else {
          this.postingSlipsCount = 0;
        }
      }
    },
    onPostingSlipGridQueryCellInfo(args) {
      const {
        data,
        cell,
      } = args;

      if (data.isDeleted) {
        cell.style.color = "red";
        cell.style.textDecoration = "line-through";
      }
    },
    onCheckInRoomSearchPopupClosed() {
      this.isCheckInRoomSearchPopup = false;
    },
    onCheckInRoomSearchButtonClicked() {
      this.isCheckInRoomSearchPopup = true;

      this.$nextTick(() => {
        this.$refs.checkInRoomSearchPopup.showPopup({
          bsnDate: this.searchOptions.bsnDate,
          rresveNo: this.checkIn.rresveNo,
        });
      });
    },
    onCheckInRoomSearchPopupConfirm(args) {
      this.onCheckInRoomSearchPopupClosed();

      this.searchOptions.roomId = args.roomId;
      this.searchOptions.guestName = args.guestName;
      this.searchOptions.stayId = args.stayId;

      this.fetchRoomCheckInPosting();
    },
    onPostingSlipGridMenuSelected(args) {
      const {
        menu,
        data,
      } = args;

      if (menu === "DELETE") {
        if (data.isPay) {
          this.errorToast("이미 정산 처리된 전표입니다");
          return;
        }

        this.isDeleteConfirmPopup = true;

        this.$nextTick(() => {
          this.$refs.deleteConfirmPopup.showPopup(data, {
            headerMessage: "전표를 삭제하시겠습니까?",
            isDeleteButton: true,
            buttonAuthNameByReg: "roomCheckInReg",
          });
        });
      }
    },
    async postingSlipDelete(data, delReason) {
      if (!data.salesId) {
        this.errorToast("전표 ID가 존재하지 않습니다");
        return;
      }

      await GolfErpAPI.roomPostingDelete(data.salesId, delReason);

      this.infoToast("삭제되었습니다");

      this.fetchRoomCheckInPosting();
    },
    onDeleteConfirmPopupClosed(args) {
      this.isDeleteConfirmPopup = false;

      if (args.isSaved) {
        this.isSaved = args.isSaved;
      }
    },
    onBatchDeleteConfirmPopupDeleteButtonClicked(args) {
      console.log('batchDelete.args.===>', args);
    },
  },
};
</script>
