<template>
  <div>
    <ejs-dialog
      ref="golfPackageLinkPopup"
      header="골프 패키지 연결"
      width="1207"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">조건검색</div>
                    <ul class="content">
                      <li class="item" style="width: 280px;">
                        <input-text
                            placeholder="예약자명, 예약번호, 단체명, 비고, 연락처 4자리"
                            v-model="searchOptions.searchValue"
                        >
                        </input-text>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item" style="width: 200px;">
                        <input-date-range
                            v-model="reservationDateRange"
                        >
                        </input-date-range>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item" style="width:120px">
                        <ejs-dropdownlist
                            :dataSource="searchOptions.bsnCodes"
                            :fields="commonCodeFields"
                            v-model="searchOptions.bsnCode"
                        >
                        </ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">부구분</div>
                    <ul class="content">
                      <li class="item" style="width:120px">
                        <ejs-dropdownlist
                            :dataSource="searchOptions.partDivs"
                            :fields="commonCodeFields"
                            v-model="searchOptions.partDiv"
                        >
                        </ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약 목록</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="reservations"
                        @headerCellInfo="onGridHeaderCellInfo"
                        @queryCellInfo="onGridQueryCellInfo"
                        @recordClick="onGridRecordClick"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onConfirmButtonClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <unlink-package-popup
      v-if="isUnlinkPackagePopup"
      ref="unlinkPackagePopup"
      @popupClosed="onUnlinkPackagePopupClosed"
    />
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import {commonCodesGetCommonCode, commonCodesGetComName, commonCodesGetSortNo} from "@/utils/commonCodes";
import {getTodayDate} from "@/utils/date";
import { orderBy as _orderBy, maxBy as _maxBy } from "lodash";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import UnlinkPackagePopup from "@/views/room-reservation-management/popup/UnlinkPackagePopup";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'GolfPackageLinkPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDateRange,
    InputText,
    UnlinkPackagePopup,
    ErpButton,
  },
  data() {
    return {
      isDelete: false,
      isUnlinkPackagePopup: false,
      rowSelectBuffer: null,
      searchOptions: {
        searchValue: null,
        resveDateFrom: null,
        resveDateTo: null,
        bsnCode: null,
        partDiv: null,
        bsnCodes: commonCodesGetCommonCode("BSN_CODE"),
        partDivs: commonCodesGetCommonCode("PART_DIV"),
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      reservations: [],
      rresveNo: null,
      isSave: false,
    };
  },
  created() {
    this.searchOptions.bsnCodes.unshift({ comCode: "%", comName: "전체" });
    this.searchOptions.partDivs.unshift({ comCode: "%", comName: "전체" });
    this.searchOptions.bsnCode = "%";
    this.searchOptions.partDiv = "%";
  },
  mounted() {},
  computed: {
    reservationDateRange: {
      get() {
        return {
          from: this.searchOptions.resveDateFrom,
          to: this.searchOptions.resveDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.resveDateFrom = dateRange.from;
        this.searchOptions.resveDateTo = dateRange.to;
      }
    },
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        isSelectedRowRetain: false,
        selectionSettings: {
          persistSelection: true,
          type: "Single",
          checkboxOnly: true,
          checkboxMode: "Default",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        columns: [
          {
            field: "isSelect",
            minWidth: 16,
            width: 50,
            type: "checkbox",
            textAlign: "Center",
          },
          {
            field: "resveName",
            headerText: "예약자명",
            minWidth: 16,
            width: 80,
            type:"string",
            textAlign: "Center",
            allowEditing: false
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            minWidth: 16,
            width: 80,
            type:"string",
            textAlign: "Center",
            groupCode:"MEMBER_DIV",
            isCommonCodeField: true,
            allowEditing: false
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            minWidth: 16,
            width: 80,
            type:"string",
            textAlign: "Center",
            groupCode:"MEMBER_GRADE",
            isCommonCodeField: true,
            allowEditing: false
          },
          {
            field: "contactTel",
            headerText: "연락처",
            minWidth: 16,
            width: 110,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
            valueAccessor: (field, data) =>
                gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "rresveNo",
            headerText: "객실예약번호",
            minWidth: 16,
            width: 120,
            type:"string",
            textAlign: "Center",
            allowEditing: false,
          },
          {
            field: "resveInfo",
            headerText: "예약정보",
            minWidth: 16,
            type:"string",
          },
          {
            field: "holeDiv",
            headerText: "홀",
            minWidth: 16,
            width: 80,
            type:"string",
            textAlign: "Center",
            isCommonCodeField: true,
            groupCode: "HOLE_DIV",
            allowEditing: false,
          }
        ],
      };
    },
  },
  methods: {
    showPopup(args) {
      this.searchOptions.resveDateFrom = args?.fromDate || getTodayDate();
      this.searchOptions.resveDateTo = args?.toDate || getTodayDate();
      this.rresveNo = args.rresveNo;
    },
    async onViewButtonClicked() {
      this.$refs.grid.clearSelection();
      this.initAllSelectionCells();

      const data = await GolfErpAPI.fetchGolfPachkageLink({
        resveDateFrom: this.searchOptions.resveDateFrom,
        resveDateTo: this.searchOptions.resveDateTo,
        bsnCode: this.searchOptions.bsnCode === "%" ? null : this.searchOptions.bsnCode,
        partDiv: this.searchOptions.partDiv === "%" ? null : this.searchOptions.partDiv,
        searchValue: this.searchOptions.searchValue,
      });

      this.reservations = _orderBy(
        data.map(item => ({
          ...item,
          rresveNo: item?.trResveGroupMap?.rresveNo || "",
          resveInfo: _orderBy(item?.tgResveConfirms?.map(tgResveConfirm => {
            return {
              resveDate: tgResveConfirm.reservationTime.resveDate,
              resveCourseName: commonCodesGetComName("COURSE_CODE", tgResveConfirm.reservationTime.resveCourse),
              resveTime: tgResveConfirm.reservationTime.resveTime,
              courseSortNo: commonCodesGetSortNo("COURSE_CODE", tgResveConfirm.reservationTime.resveCourse),
            };
          }), ["resveDate", "resveTime", "courseSortNo"]).map(i => i.resveDate + "/" + i.resveCourseName + "/" + i.resveTime).join(", "),
          holeDiv: _maxBy(item?.tgResveConfirms?.map(tgResveConfirm => tgResveConfirm.reservationTime.holeDiv)),
          sortNoByResveCourse: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),
        })),
        ["resveName", "resveDate", "resveTime", "sortNoByResveCourse"]
      );
    },
    removeSelectionCells() {
      Array.from(document.querySelectorAll(".e-selectionbackground"))
        .filter((el) => !el.hasAttribute("aria-selected"))
        .forEach((el) => {
          el.classList.remove("e-selectionbackground");
          el.classList.remove("e-active");
        });
    },
    createFocusedCells() {
      const focusedRowCells =
        this.$refs.grid.getRows()[this.rowSelectBuffer]
          ?.cells;
      if (focusedRowCells && focusedRowCells?.length > 0) {
        Array.from(focusedRowCells).forEach((el) => {
          el.classList.add("e-selectionbackground");
          el.classList.add("e-active");
        });
      }
    },
    initAllSelectionCells() {
      this.rowSelectBuffer = null;
      Array.from(document.querySelectorAll(".e-selectionbackground")).forEach(
        (el) => {
          el.classList.remove("e-selectionbackground");
          el.classList.remove("e-active");
        }
      );
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;

      if (field === "rresveNo") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
      } = args;

      if (field === "isSelect") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (field === "rresveNo") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData,
      } = args;

      if(args.column){
        this.removeSelectionCells();
        if(field !== "isSelect"){
          this.rowSelectBuffer = args.rowIndex;
          this.createFocusedCells();
        }
      }

      if (field === "rresveNo") {
        if (rowData["rresveNo"]) {
          this.isUnlinkPackagePopup = true;

          this.$nextTick(() => {
            this.$refs.unlinkPackagePopup.showPopup({
              rresveNo: rowData["rresveNo"],
              resveNo: rowData["resveNo"],
            });
          });
        }
      }
    },
    onUnlinkPackagePopupClosed(isDelete) {
      this.isUnlinkPackagePopup = false;

      if (isDelete) {
        this.isDelete = isDelete;
        this.onViewButtonClicked();
      }
    },
    async onConfirmButtonClicked() {
      const selectedRecords = this.$refs.grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("1건 이상의 골프예약 정보를 선택하시기 바랍니다");
        return;
      }

      if (selectedRecords.filter(item => item.rresveNo).length > 0) {
        this.errorToast("이미 연결된 골프 예약 정보는 선택이 불가능 합니다");
        return;
      }

      if (!(await this.confirm("선택한 골프예약 정보를 객실 패키지로 연결 하시겠습니까?"))) {
        return;
      }

      const resveNos = selectedRecords.map(item => item.resveNo);

      await GolfErpAPI.golfPackageLink(this.rresveNo, resveNos);

      this.infoToast("저장되었습니다");

      this.isSave = true;

      this.onCloseButtonClicked();
    },
    onCloseButtonClicked() {
      this.$refs.golfPackageLinkPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", this.isSave, this.isDelete);
    },
  },
};
</script>
