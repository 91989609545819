<template>
  <div>
    <ejs-dialog
      ref="vacantRoomPopup"
      header="Vacant Room"
      width="1007"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :enableResize="true"
      :close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">투숙기간</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          type="lookup-condition"
                          disabled="true"
                          v-model="stayDateRange"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">객실타입</div>
                    <ul class="content">
                      <li class="item" style="width: 100px">
                        <ejs-dropdownlist
                            v-model="searchOptions.roomType"
                            :fields="commonCodeFields"
                            :dataSource="searchOptions.roomTypeCodes"
                        >
                        </ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">객실번호</div>
                    <ul class="content">
                      <li class="item input">
                        <input-text
                            v-model="searchOptions.roomNo"
                        ></input-text>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                  type="checkbox"
                                  value="true"
                                  v-model="searchOptions.isAvailableRooms"
                              />
                              <i></i>
                              <div class="label">사용 가능 객실</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                  type="checkbox"
                                  value="true"
                                  v-model="searchOptions.isVirtlRoomFlag"
                              />
                              <i></i>
                              <div class="label">가상 객실</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">객실 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(vacantRoomsCount) }} 건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="vacantRooms"
                        :isInPopup="true"
                        @onGridDialogDoubleClickedOrEnterKeyed="onConfirmButtonClicked"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onConfirmButtonClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputText from "@/components/common/text/InputText";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

import {commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import {numberWithCommas} from "@/utils/number";
import { sortBy as _sortBy } from "lodash";

export default {
  name: 'VacantRoomPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputDateRange,
    EjsGridWrapper,
    InputText,
    ErpButton,
  },
  data() {
    return {
      searchOptions: {
        arrivalDate: null,
        departureDate: null,
        roomType: null,
        roomNo: null,
        isAvailableRooms: true,
        isVirtlRoomFlag: false,
        roomTypeCodes: commonCodesGetCommonCode("ROOM_TYPE"),
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      vacantRooms: [],
      vacantRoomsCount: 0,
      stayId: null,
      rtnRoomInfo: null,
      rowIndex: null,
      roomIds: [],
    };
  },
  created() {
    if (
      this.searchOptions.roomTypeCodes.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.searchOptions.roomTypeCodes.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchOptions.roomType = "%";
  },
  mounted() {},
  computed: {
    stayDateRange: {
      get() {
        return {
          from: this.searchOptions.arrivalDate,
          to: this.searchOptions.departureDate,
        };
      },
      set(stayDateRange) {
        this.searchOptions.arrivalDate = stayDateRange.from;
        this.searchOptions.departureDate = stayDateRange.to;
      },
    },
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        columns: [
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            headerText: "객실상태",
            textAlign: "Center",
            type: "string",
            field: "realRoomStatus",
            width: 100,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_STATUS',
          },
          {
            headerText: "청소상태",
            textAlign: "Center",
            type: "string",
            field: "cleanStatus",
            width: 80,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'CLEAN_STATUS',
          },
          {
            headerText: "동",
            textAlign: "center",
            type: "string",
            field: "dongDiv",
            width: 80,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'DONG_DIV',
          },
          {
            field: "floor",
            type: "string",
            headerText: "층",
            textAlign: "Center",
            width: 60,
          },
          {
            headerText: "객실방향",
            textAlign: "center",
            type: "string",
            field: "roomDrc",
            width: 120,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_DRC',
          },
          {
            headerText: "객실유형",
            textAlign: "center",
            type: "string",
            field: "roomKind",
            width: 100,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_KIND',
          },
          {
            headerText: "가상여부",
            textAlign: "Center",
            field: "virtlRoomFlag",
            width: 100,
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    showPopup(args) {
      this.searchOptions.arrivalDate = args.arrivalDate;
      this.searchOptions.departureDate = args.departureDate;
      this.searchOptions.roomType = args.roomType || "%";
      this.stayId = args.stayId;
      this.rowIndex = args.rowIndex;
      this.roomIds = args.roomIds || [];

      this.onViewButtonClicked();
    },
    onViewButtonClicked() {
      this.fetchVacantRoom();
    },
    async fetchVacantRoom() {
      this.vacantRooms = _sortBy(await GolfErpAPI.fetchVacantRoom({
        arrivalDate: this.searchOptions.arrivalDate,
        departureDate: this.searchOptions.departureDate,
        roomType: this.searchOptions.roomType === "%" ? null : this.searchOptions.roomType,
        roomNo: this.searchOptions.roomNo,
        isAvailableRooms: this.searchOptions.isAvailableRooms,
        isVirtlRoomFlag: this.searchOptions.isVirtlRoomFlag,
      }), ["roomNo", "dongDiv", "floor"])
        .filter(item => item.realRoomStatus === "EXPECTED" ? true : !this.roomIds.includes(item.roomId))
      ;

      this.vacantRoomsCount = this.vacantRooms.length;
    },
    onConfirmButtonClicked() {
      const records = this.$refs.grid.getSelectedRecords();

      if (!(records.length > 0)) {
        this.errorToast("선택된 객실이 없습니다");
        return;
      }

      const record = records[0];

      if (record.realRoomStatus === "NAMING") {
        if (record.stayId !== this.stayId) {
          this.errorToast("이미 가배정된 객실입니다");
          return;
        }
      } else if (record.checkinAvail !== "Y") {
        this.errorToast(`[${commonCodesGetComName("ROOM_STATUS", record.realRoomStatus)}] 객실은 선택할 수 없습니다`);
        return;
      }

      this.rtnRoomInfo = record;

      this.onCloseButtonClicked();
    },
    onCloseButtonClicked() {
      this.$refs.vacantRoomPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed", this.rowIndex, this.rtnRoomInfo);
    },
  },
};
</script>
