<template>
  <div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      position="{ x: 'center', y: 'center' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="387"
      :close="onPopupClose"
    >
    <div class="window lookupDetail-advancePaymentRegistration">
      <div class="windowContent">
        <div class="content-wrapper">
          <div class="content-lookupDetail">
            <ul class="lookupDetail-condition">
              <li class="field">
                <div class="title">예약번호</div>
                <ul class="content">
                  <li class="item">
                    <input-text
                      v-model="searchOptions.rresveNo"
                      @keydown.native="formPreventEnterEvent"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">예약상태</div>
                <ul class="content">
                  <li class="item">
                    <ejs-multiselect
                      v-model="searchOptions.rresveStatusList"
                      cssClass="lookup-condition-multiselect"
                      :dataSource="searchOptions.rresveStatusCodes"
                      :fields="commonCodeFields"
                      placeHolder="전체"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">퇴실일자</div>
                <ul class="content">
                  <li class="item" style="width: 50px;">
                    <ul class="check">
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            v-model="searchOptions.isDepartureDateUse"
                            :checked="searchOptions.isDepartureDateUse"
                          />
                          <i></i>
                          <div class="label">사용</div>
                        </label>
                      </li>
                    </ul>
                  </li>
                  <li class="item dateRange">
                    <input-date-range
                      type="lookup-condition"
                      v-model="searchOptions.departureDateRangeValue"
                      :disabled="!searchOptions.isDepartureDateUse"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">예약일자</div>
                <ul class="content">
                  <li class="item" style="width: 50px;">
                    <ul class="check">
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            v-model="searchOptions.isResveDateUse"
                            :checked="searchOptions.isResveDateUse"
                          />
                          <i></i>
                          <div class="label">사용</div>
                        </label>
                      </li>
                    </ul>
                  </li>
                  <li class="item dateRange">
                    <input-date-range
                      type="lookup-condition"
                      v-model="searchOptions.resveDateRangeValue"
                      :disabled="!searchOptions.isResveDateUse"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">예약종류</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.resveKind"
                      :dataSource="searchOptions.resveKindCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">예약채널</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.resveChannel"
                      :dataSource="searchOptions.resveChannelCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">객실타입</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.roomType"
                      :dataSource="searchOptions.roomTypeCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">회원구분</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.memberDiv"
                      :dataSource="searchOptions.memberDivCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">회원등급</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.memberGrade"
                      :dataSource="searchOptions.memberGradeCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">판매유형</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.roomSaleKind"
                      :dataSource="searchOptions.roomSaleKindCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">할인유형</div>
                <ul class="content">
                  <li class="item">
                    <ejs-dropdownlist
                      v-model="searchOptions.roomDcKind"
                      :dataSource="searchOptions.roomDcKindCodes"
                      :fields="commonCodeFields"
                      :allowFiltering="false"
                      cssClass="lookupDetail-condition-dropdown"
                    />
                  </li>
                </ul>
              </li>
              <li class="field">
                <div class="title">골프패키지</div>
                <ul class="content">
                  <li class="item">
                    <ul class="check">
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            v-model="searchOptions.isGolfPackage"
                            :checked="searchOptions.isGolfPackage"
                          />
                          <i></i>
                          <div class="label">사용</div>
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="windowFooter">
        <ul class="button">
          <li class="lookup keyColor">
            <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="viewButtonClicked"
            >
              조회
            </erp-button>
          </li>
          <li class="reset">
            <erp-button
              button-div="GET"
              @click.native="onInitButtonClicked"
            >
              초기화
            </erp-button>
          </li>
          <li class="close">
            <erp-button button-div="CLOSE" @click.native="onSearchDetailPopupClose">
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from '@/components/common/datetime/InputDateRange';
import ErpButton from "@/components/button/ErpButton.vue";

import {getFormattedDate} from "@/utils/date";
import { formPreventEnterEvent, } from "@/utils/formUtil";

export default {
  name: "RoomReservationRegistrationSearchDetailPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputDateRange,
    ErpButton,
  },
  props: {
    searchOptions: {
      type: Object,
      default: function () {
        return {
          rresveNo: null,
          rresveStatusList: [],
          isDepartureDateUse: false,
          isResveDateUse: false,
          departureDateRangeValue: {
            from: getFormattedDate(new Date()),
            to: getFormattedDate(new Date()),
          },
          resveDateRangeValue: {
            from: getFormattedDate(new Date()),
            to: getFormattedDate(new Date()),
          },
          resveKind: "ALL",
          resveChannel: "ALL",
          roomType: "ALL",
          memberDiv: "ALL",
          memberGrade: "ALL",
          roomSaleKind: "ALL",
          roomDcKind: "ALL",
          isGolfPackage: false,
        };
      },
    }
  },
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
    };
  },
  mounted() {},
  computed: {
    isPopupOpened() {
      return false;
    },
  },
  methods: {
    formPreventEnterEvent,
    viewButtonClicked() {
      this.$emit("viewButtonClicked");
    },
    onInitButtonClicked() {
      this.$emit("onInitButtonClicked");
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
  },
};
</script>